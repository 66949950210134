import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//hooks
import useUploadFileHook from 'src/webapp/hooks/comn/uploadFile.hook';
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//Comp
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//입력폼
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//입력폼내제목
import ImgArea from 'src/webapp/views/component/ImgArea';//차량모델이미지

const ItemIndex02 = forwardRef((props, childRef) => {
    const { isDisplay, data, inputRef } = props;//props
    //상위컴포넌트에서 호출
    useImperativeHandle(childRef, () => ({
        uploadBrandItemImageAPI
    }));
    const { axiosMultiPartHandler } = useAxiosHook();//axios
    const { admin } = useAdminStore();//stores
    //팝업메세지
    const { setOnMessageHandler } = useComnHook();
    //파일추가
    const { files, setFiles//fileuseState
        , MAX_COUNT//최대업로드갯수
        , addFileHandler//파일업로드핸들러
    } = useUploadFileHook();
    /* ==================== 브랜드모델이미지업로드 ==================== */
    const uploadBrandItemImageAPI = async(imgCd, brandCd, type) => {//type - 1.등록 2.수정
        const msgType = (type === 1 ? '등록' : type === 2 ? '수정' : null);
        if(files.length <= 0) { setOnMessageHandler(1, (msgType + '이 완료되었습니다.')); return; }

        const DATA = new FormData();
        let variables = { IMG_CD:imgCd, BRAND_CD:brandCd, TYPE:2, LAST_UPDATE_USER:admin.COMN_ID, SUBMIT_TYPE:type };
        for(let i = 0; i < files.length; i++) { DATA.append('file', files[i].file); }
        DATA.append('requestDTO', new Blob([JSON.stringify(variables)]), { type: "application/json" });
        const result = await axiosMultiPartHandler(`brandImgRegAndUpdateForJson.do`, DATA);
        if (!result) { setOnMessageHandler(2, (msgType + '이 완료되지 않았어요. 다시 시도해주세요.')); return; }
        if (result.message != "SUCCESS") { setOnMessageHandler(2, (msgType + '이 완료되지 않았어요. 다시 시도해주세요.')); return; }
        if (result.message == "SUCCESS") { setOnMessageHandler(1, (msgType + '이 완료되었습니다.'));props.choose({BRAND_CD:brandCd}); return; }
    }
    /* ==================== 브랜드모델이미지업로드 ==================== */
    /* ==================== validationCheck ==================== */
    const onValidateHandler = () => {
        for(let i = 0; i < inputRef.current.length; i++) {
            if (inputRef.current[i].value.length <= 0) { props.onValid(null, true); return; }
        }
        if(data === null && files.length <= (MAX_COUNT.current - 1)) { props.onValid(null, true); return; }
        props.onValid(null, false);
    }
    useEffect(() => {
        onValidateHandler();
    }, [files]);
    useEffect(() => {
        if(isDisplay) { onValidateHandler(); }//validateCheck
    }, [isDisplay]);
    /* ==================== validationCheck ==================== */

    return (
        <div style={{ display:(isDisplay ? 'flex' : 'none') }} className="popupFormArea">
            <FormWrapper>
                <TitleWrapper title={'차종(모델명) - 한글'} />
                <div className="textArea">
                    <input onChange={(e) => onValidateHandler()}
                        ref={el => (inputRef.current[0] = el)}
                        type="text" inputMode="text" placeholder="내용을 입력해주세요." 
                        defaultValue={data !== null ? data.MODEL_KOR_NAME : null} />
                </div>
            </FormWrapper>
            <FormWrapper>
                <TitleWrapper title={'차종(모델명) - 영문'} />
                <div className="textArea">
                    <input onChange={(e) => onValidateHandler()}
                        ref={el => (inputRef.current[1] = el)}
                        type="text" inputMode="text" placeholder="내용을 입력해주세요." 
                        defaultValue={data !== null ? data.MODEL_ENG_NAME : null} />
                </div>
            </FormWrapper>
            {data !== null && data.IMG_SRC !== undefined? 
                <FormWrapper><TitleWrapper title={'현재 이미지'} />
                    <div className="imgPreview">
                        <div className="previewImage"><ImgArea src={data.IMG_SRC} alt={data.MODEL_KOR_NAME} />  </div>
                        <label>{data.MODEL_KOR_NAME + '.webp'}</label>
                    </div>
                </FormWrapper>
            : null}
            <FormWrapper><TitleWrapper title={'차량 이미지'} />
                {files.length < MAX_COUNT.current ?
                    <div className="fileArea">
                        <label htmlFor="file"><span>이미지 업로드</span><FontAwesomeIcon icon="fa-light fa-square-dashed-circle-plus" /></label>
                        <input onChange={(e) => addFileHandler(e)} type="file" id="file" accept='image/*' />
                    </div>
                : null}
                {files.length > 0 && files.map((file, idx) => (
                    <div key={file.id} className="imgPreview">
                        <button onClick={(e) => setFiles([])}><FontAwesomeIcon icon="fa-light fa-xmark" /></button>
                        <div className="previewImage"><img src={file.url} alt="preview-img" /></div>
                        <label>{file.id}</label>
                    </div>
                ))}
            </FormWrapper>
        </div>
    );
})
export default ItemIndex02;