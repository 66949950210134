import React, { useEffect, useState, useRef } from 'react';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import Search from './Search';//회원검색
import List from './List';//회원목록
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//submitPopupMessage

export default function MemberList() {
    const { axiosPostHandler } = useAxiosHook();//axios
    //회원리스트API
    const isLock = useRef(false);//더블클릭방지Ref
    const searchRef = useRef([]);//검색어
    const pageRef = useRef(20);//infinityObserver페이지DOMRef
    const scrollRef = useRef();//elementScroll관리Ref

    const [ memberList, setMemberList ] = useState([]);
    const memberListAPI = async(type) => {//0:무한로드, 1:검색 
        if((type === 0 && pageRef.current == null) || isLock.current) { return; }
        isLock.current = true;
        pageRef.current = (type === 0 ? pageRef.current : 20) ;
        
        const MEMBER_NAME = searchRef?.current[0]?.value !== '2' ? searchRef.current[2].value : '';
        const NICK_NAME = searchRef?.current[0]?.value !== '1' ? searchRef.current[2].value : '';
        const STATE = searchRef.current[1].value === 'ALL' ? '' : searchRef.current[1].value;
        const DATA = { ROWNUM:pageRef.current, MEMBER_NAME:MEMBER_NAME, NICK_NAME:NICK_NAME, STATE:STATE };
        const result = await axiosPostHandler(`memberMasterListForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { 
            if(pageRef.current === 20) { setMemberList([]); }
            pageRef.current = null;
        }
        if(result.message === "SUCCESS") { 
            setMemberList(pageRef.current === 20 ? result.data : memberList.concat(result.data));
            pageRef.current = result.data.length < 20 ? null : pageRef.current + 20;
            if(type === 1) {//스크롤 최상단으로 올리기
                scrollRef.current?.scrollIntoView({ behavior:'instant' });
            }
        }
    }
    //회원카운트API
    const [ cnt,setCnt ] = useState(0);
    const memberCntAPI = async() => {
        const MEMBER_NAME = searchRef?.current[0]?.value !== '2' ? searchRef.current[2].value : '';
        const NICK_NAME = searchRef?.current[0]?.value !== '1' ? searchRef.current[2].value : '';
        const STATE = searchRef?.current[1]?.value !== 'ALL' ? searchRef.current[1].value : '';
        const DATA = { NICK_NAME:NICK_NAME, MEMBER_NAME:MEMBER_NAME, STATE:STATE };
        const result = await axiosPostHandler(`memberCntByStateForJson.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(result.message === "INVALID_DATA") { }
        if(result.message === "SUCCESS") { setCnt(result.data.result); }
    }
    useEffect(() => {
        memberListAPI(1);//회원리스트API
        memberCntAPI();//회원카운트API
    }, []);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { memberListAPI(0); }//알림리스트API
    }, [inView]);

    return ( 
        <>
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`멤버스`} cnt={`${cnt}명`} dept={[{label:'회원관리'}, {label:'멤버스'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FixedPanel>
                    <Search search={searchRef} onSearch={memberListAPI} onState={memberCntAPI} />
                </FixedPanel>
                <FlickPanel>
                    <Element tag={`div`} className="fullHeight" propRef={el => (scrollRef.current = el)}>
                        <List list={memberList} setMemberList={setMemberList} />
                        {memberList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
                        : memberList.length > 0 && pageRef.current === null ? <InViewData readAll={true} /> 
                        : null}
                    </Element>
                </FlickPanel>
            </Container>
        </Section>
        <SubmitPopup />
        </>
    );
}