import React, { forwardRef, useRef } from 'react';
//modalPopup
import Modal from 'react-modal';
import 'src/asset/scss/plugin/modalPopup.scss';
//hook
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';//comp
//Comp
import Index from './ItemIndex';//브랜드명&이미지입력

const BrandUpdate = forwardRef((props) => {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
            height: "100%"
        }
    };
    const { isOpen, data } = props;//props
    const childRef = useRef({});
    //hook
    const { brandMasterRegAndUpdateAPI//브랜드 등록 및 수정API
        , brandRef//선택된브랜드Ref
    } = useBrandHook();

    /* ==================== 팝업오픈핸들러 ==================== */
    const onCloseHandler = (isLoad) => {
        props.onDismiss('update', false, null, isLoad);
    }
    /* ==================== 팝업오픈핸들러 ==================== */
    /* ==================== 제조사수정 ==================== */
    const onSubmitHandler = async () => {
        const result = await brandMasterRegAndUpdateAPI(data.current, 2);
        if(!result) { onCloseHandler(false); }
        const imgResult = await childRef.current.uploadBrandImageAPI(result.result, 1);//BRAND_CD코드값, SUBMIT_TYPE:등록
        onCloseHandler(true);
    }
    /* ==================== 제조사수정 ==================== */

    class BrandAdd extends React.Component {
        state = { isDisabled:true };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true }); 
        };
        onValidItem = (item, value) => {
            if(this.state.isDisabled != value) {
                this.setState({...this.state, isDisabled:value });
            }
        };
        render() { const { index } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles} ariaHideApp={false}>
                <div className="popupContainer">
                    <div className="popupHeader"><h1>제조사 수정</h1></div>
                    <div className="popupContainer">
                        <div className="popupFormArea">
                        <Index ref={childRef} inputRef={brandRef} data={data.current} onValid={this.onValidItem} />
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="btnArea">
                            <button onClick={(e) => onCloseHandler(false)} className="secondaryButton"><span>취소</span></button>
                            <button disabled={this.state.isDisabled} onClick={(e) => onSubmitHandler()} className="submitButton"><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </Modal>
        )}
    } return ( <BrandAdd /> )
})
export default BrandUpdate;