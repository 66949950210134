import React, { forwardRef, useRef } from 'react';
//modalPopup
import Modal from 'react-modal';
import 'src/asset/scss/plugin/modalPopup.scss';
//hook
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import Index from './ItemIndex';//브랜드명&이미지입력

const BrandAdd = forwardRef((props) => {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
            height: "100%"
        }
    };
    const { isOpen } = props;//props
    const childRef = useRef({});
    //hook
    const { brandMasterRegAndUpdateAPI//브랜드 등록 및 수정API
        , brandRef//선택된브랜드Ref
    } = useBrandHook();

    /* ==================== 팝업오픈핸들러 ==================== */
    const onCloseHandler = (isLoad) => {
        props.onDismiss('add', false, null, isLoad);
    }
    /* ==================== 팝업오픈핸들러 ==================== */
    /* ==================== 제조사등록 ==================== */
    const onSubmitHandler = async () => {
        const result = await brandMasterRegAndUpdateAPI(null, 1);
        if(!result) { onCloseHandler(false); }
        const imgResult = await childRef.current.uploadBrandImageAPI(result.result, 1);//BRAND_CD코드값, SUBMIT_TYPE:등록
        onCloseHandler(true);
    }
    /* ==================== 제조사등록 ==================== */

    class BrandAdd extends React.Component {
        state = { isDisabled: true };
        handleChange = (e, value) => {
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled: true });
        };
        onValidItem = (item, value) => {
            if (this.state.isDisabled != value) {
                this.setState({ ...this.state, isDisabled: value });
            }
        };
        render() {
            const { index, isDisabled } = this.state;

            return (
                <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles} ariaHideApp={false}>
                    <Element tag={`div`} className="popupContainer">
                        <Element tag={`div`} className="popupHeader"><Element tag={`hi`}>제조사 추가</Element></Element>
                        <Element tag={`div`} className="popupContainer">
                            <Element tag={`div`} className="popupFormArea">
                                <Index ref={childRef} inputRef={brandRef} data={null} onValid={this.onValidItem} />
                            </Element>
                        </Element>
                        <Element tag={`div`} className="popupFooter">
                            <Element tag={`div`} className="btnArea">
                                <button onClick={(e) => onCloseHandler(false)} className="secondaryButton"><Element tag={`span`}>취소</Element></button>
                                <button disabled={isDisabled} onClick={(e) => onSubmitHandler()} className="submitButton"><Element tag={`span`}>확인</Element></button>
                            </Element>
                        </Element>
                    </Element>
                </Modal>
            )
        }
    } return (<BrandAdd />)
})
export default BrandAdd;