import React, { forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
//styles
import styles from 'src/asset/scss/styles/brandList.module.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//hooks
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import EmptyData from 'src/webapp/views/component/data/EmptyData';//리스트데이터none
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인
import ImgArea from 'src/webapp/views/component/ImgArea';//브랜드로고이미지
import ItemToggle from './ItemToggle';//toggleSwitch
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//submit & error & notice 메세지

const BrandList = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({//상위컴포넌트에서 호출
        brandDeleteAPI,
        brandListAPI
    }));
    //hook
    const { axiosPostHandler } = useAxiosHook();//axios
    const { pageRef,//무한로딩페이지DOM
        brandList,//브랜드리스트데이터
        brandListAPI//브랜드리스트API
    } = useBrandHook();
    const { setOnMessageHandler } = useComnHook();//submitPopup메세지처리
    //최초로드
    useEffect(() => {
        brandListAPI(null, true);//브랜드리스트API
    }, []);
    //infinityObserver
    const [inViewRef, inView] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { brandListAPI(null, true); }//브랜드리스트API
    }, [inView]);

    /* ==================== 브랜드삭제 ==================== */
    const checkList = useRef([]);//수락 또는 거절 체크리스트
    const brandDeleteAPI = async () => {
        let DATA = [];
        for (let i = 0; i < checkList.current.length; i++) {
            if (checkList.current[i] != null) {
                const obj = JSON.parse(checkList.current[i].value);
                const BRAND_CD = obj.BRAND_CD;
                if (checkList.current[i].checked) {
                    const obj = { BRAND_CD: BRAND_CD };
                    DATA.push(obj);
                }
            }
        }
        if(DATA.length <= 0) { setOnMessageHandler(3, '1개 이상 데이터를 선택해주세요.'); return; }

        if(!window.confirm("데이터 삭제 후 복구가 어려울 수 있습니다.\n해당데이터를 삭제하시겠습니까?")) { return; }
        const result = await axiosPostHandler(`brandMasterDeleteForJson.do`, DATA);
        if (!result) { setOnMessageHandler(2, '삭제가 완료되지 않았어요. 다시 시도해주세요.'); }
        if (result.message === "SUCCESS") {
            setOnMessageHandler(1, '삭제가 성공적으로 처리되었습니다.');
            brandListAPI(true, true);
        }
    }
    /* ==================== 브랜드삭제 ==================== */
    /* ==================== 메세지출력 ==================== */
    const onMessageHandler =(type, msg) => {//** Toggle(Class)에서 실행안됨
        setOnMessageHandler(type, msg);
    }
    /* ==================== 메세지출력 ==================== */

    return (
        <>
        <Element tag={`div`} className={styles.brandListArea}>
            {brandList.length > 0 ?
                <Element tag={`ul`}>
                    {brandList.map((item, i) => (
                        <Element tag={`li`} key={i}>
                            <input type='checkbox' ref={el => (checkList.current[i] = el)} value={JSON.stringify(item)} />
                            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={`brand/logo/gray` + item.IMG_SRC} alt={item.BRAND_KOR_NAME} />
                            <Element tag={`div`} style={{ width: "5.2rem" }}><Element tag={`label`}>{item.BRAND_ENG_NAME}</Element></Element>
                            <Element tag={`div`} className={styles.stretch}><button onClick={(e) => props.onDismiss('update', true, item, false)}><FontAwesomeIcon icon="fa-light fa-pen-to-square" /></button></Element>
                            <Element tag={`div`} className={styles.toggleArea}><ItemToggle item={item} onMessage={onMessageHandler} /></Element>
                        </Element>
                    ))}
                </Element>
                : <EmptyData />}
            {brandList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div> : null}
        </Element>
        <SubmitPopup />
        </>
    )
})
export default BrandList;