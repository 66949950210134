import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userPartnerVehicleReg.module.scss';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
//comp
import EmptyData from 'src/webapp/views/component/data/EmptyData';//리스트데이터none
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//submit & error & notice 메세지

export default function ModelList({brand}) {
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//
    const { setOnMessageHandler } = useComnHook();//메세지처리
    //Hook
    const { checkList
        , modelList//브랜드모델리스트전체
        , brandItemListAPI//브랜드모델리스트API
    } = useBrandHook();//axios
    //제조사별모델리스트
    useEffect(() => {(async () => {
        if(brand !== null) { brandItemListAPI(2, brand.BRAND_CD); }//브랜드모델리스트API(브랜드모델리스트전체)
    })()}, [brand]);

    /* ==================== 파트너차량등록 ==================== */
    const [ checkedItem, setCheckedItem ] = useState([]);//선택모델state
    //파트너차량등록API
    const VehicleRegAPI = (async () => {
        let DATA = [];
        for(let i=0; checkedItem.length > i; i++){
            DATA.push({ PARTNER_CD:params.cd, BRAND_CD:checkedItem[i].BRAND_CD, MODEL_CD:checkedItem[i].MODEL_CD });
        }
        console.log(DATA);
        const result = await axiosPostHandler(`partnerVehicleRegForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message != "SUCCESS") { 
            setOnMessageHandler(2, '일시적인 오류가 발생했습니다.');}
        if(result.message == "SUCCESS") { 
            setOnMessageHandler(1, '차량이 등록되었습니다.'); 
        } 
    });
    /* ==================== 파트너차량등록 ==================== */

    return ( 
        <>
        <Element tag={`div`} className={styles.modelListArea}>
            {brand !== null ? 
                <>
                {modelList.length > 0 ? 
                    <Element tag={`ul`}>
                        {modelList.map((item, i) => 
                            <Element anyprop={(e) => setCheckedItem(!checkedItem.includes(item) ? [...checkedItem, item] : checkedItem.filter((item) => modelList[i] != item))} tag={`li`} key={item.MODEL_CD}>
                                <input type='checkbox' checked={checkedItem.includes(item)} onChange={(e) => setCheckedItem(e.target.checked ? [...checkedItem, item] : checkedItem.filter((item) => modelList[i] != item))} ref={el => (checkList.current[i] = el)} value={JSON.stringify(item)} />
                                <Element tag={`span`}>{item.MODEL_KOR_NAME}</Element>
                            </Element>
                        )}
                    </Element>
                : <EmptyData />}
                </>
            : <Element tag={`h2`}>제조사를 먼저 선택해주세요.</Element> }
        </Element>
        <Element tag={`div`} className={styles.btnArea}>
            <Link to={-1}><FontAwesomeIcon icon="fa-light fa-chevron-left" /><Element tag={`span`}>이전으로</Element></Link>
            {brand !== null ? 
                <>
                <Button anyprop={(e) => checkedItem.length !== modelList.length ? setCheckedItem(modelList) : setCheckedItem([])} disabled={modelList.length > 0 ? false : true} icon={"fa-light fa-folder-open"} label={checkedItem.length > 0 ? '전체해제' : '전체선택'} ></Button> 
                <Button anyprop={VehicleRegAPI} icon={"fa-light fa-floppy-disk"} disabled={checkedItem.length <= 0} label={"등록하기"} ></Button>
                </>
            : null}
        </Element>
        <SubmitPopup />
        </>
    );
}