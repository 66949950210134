import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';

const useReservHook = () => {
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//파라미터
    //ref
    const scrollRef = useRef();//elementScroll관리Ref
    const pageRef = useRef(20);//페이지DOM
    const isLock = useRef(false);//더블클릭방지DOM
    
    //에약목록API
    const searchRef = useRef([]);
    const [ reservList, setReservList ] = useState([]);
    const reservListAPI = async(type, page) => {//type-0:무한로드, 1:검색, page-0:대시보드, 1:대시보드>예약목록, 2:파트너정보>스케줄보기
        if((type === 1 && page === 1 && isLock.current) && (page === 0 && (isLock.current || pageRef.current === null))) { return; }
        
        isLock.current = true;
        //pageRef.current = (type === 0 ? pageRef.current : 20) ;
        const ROWNUM = (type === 0 ? pageRef.current : 20)
        const DATA = page === 0 ? { ROWNUM:ROWNUM } 
            : page === 1 ? { ROWNUM:ROWNUM, 
                MEMBER_NAME:(searchRef?.current[0]?.value === 1 ? searchRef?.current[1]?.value : ""), PARTNER_NAME:(searchRef?.current[0]?.value === 2 ? searchRef?.current[1]?.value : ""),
                START_DT:searchRef.current[2].value.replaceAll("-", ""), END_DT:searchRef.current[3].value.replaceAll("-", "") }
            : page === 2 ? { ROWNUM:ROWNUM, PARTNER_CD:params.cd, START_DT:searchRef.current[0].value.replaceAll("-", ""), END_DT:searchRef.current[1].value.replaceAll("-", "") }
            : null;
            const result = await axiosPostHandler(`reservListByDayForAdmin.do`, DATA);
            setTimeout(() => { isLock.current = false; }, 500);
            if(!result) { return; }
            if(result.message === "INVALID_DATA") { 
                if(pageRef.current === 20) { setReservList([]); }
                pageRef.current = null;
            }
            if(result.message === "SUCCESS") { 
                setReservList(type === 1 ? result.data : reservList.concat(result.data));
                pageRef.current = result.data.length < 20 ? null : (pageRef.current === null || type === 1 ? 20 : pageRef.current) + 20;
                if(type === 1 && page === 1) {
                    scrollRef.current?.scrollIntoView({ behavior:'instant' });//애니메이션옵션 속성값 behavior:'smooth'
                }
            }
    }

    return { scrollRef//elementScroll
        , pageRef//페이지DOM정보
        , searchRef//회원예약내역검색어DOM 
        , reservListAPI, reservList,//회원예약내역데이터
    };
}

export default useReservHook;