import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
import buttonStyles from 'src/asset/scss/component/button.module.scss';
//store
import useStore from 'src/webapp/stores/admin.store';
//comp
import Element from 'src/webapp/views/wrapper/Element';//기본엘리먼트
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음
import SubtitleArea from 'src/webapp/views/wrapper/SubtitleArea';//서브타이틀공통컴포넌트
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
import List from './itemList/List';//아이템리스트데이터

export default function ItemList(props) {
    const params = useParams();//queryParameter
    //hook
    const { axiosPostHandler } = useAxiosHook();//axios
    //Store
    const { admin } = useStore();

    /* ==================== 파트너아이템리스트 ==================== */
    //파트너아이템리스트API
    const [itemList, setItemList] = useState(null);
    const partnerItemListAPI = async () => {
        const DATA = { PARTNER_CD: params.cd };
        const result = await axiosPostHandler(`partnerItemListByPartnerForAdmin.do`, DATA);
        if (!result) { return; }
        if (result.message === "INVALID_DATA") { setItemList({ data: [], size: 0 }); }
        if (result.message === "SUCCESS") {
            //itemListGroup(result.data.filter((item) => item.DEL_YN !== "Y"), 1);
            itemListGroup(result.data);
        }
    }
    const [inViewRef, inView] = useInView({ threshold: .3 });
    useEffect(() => {
        if (inView && itemList === null) { partnerItemListAPI(); }//업체별리뷰리스트API
    }, [inView]);
    /* ==================== 파트너아이템리스트 ==================== */
    /* ==================== 파트너아이템 및 삭제 그룹화 함수 ==================== */
    //브랜드별그룹화함수
    const itemListGroup = (data) => {
        const array = data, hash = Object.create(null), grouped = [];
        array.forEach(function (o, i) {
            const key = ['CATEGORY'].map(function (k) { return o[k]; }).join('|');
            if(!hash[key]) {
                hash[key] = { category:o.CATEGORY, itemGroup:[] };
                grouped.push(hash[key]);
            }
            hash[key]['itemGroup'].push(o);
        });
        setItemList({ data:grouped, size:data.length });
        // if(type === 1) { setItemList({ data:grouped, size:data.length }); }
        // if(type === 2) { setItemList((prevState) => { return { ...prevState, [data]:grouped }; }); }
    }
    /* ==================== 파트너아이템 및 삭제 그룹화 함수 ==================== */
    //하위컴포넌트함수호출
    const childRef = useRef({});
    const [ checkedItems, setCheckedItems ] = useState([]);//복사 및 삭제 체크박스선택
    /* ==================== 아이템복사하기 ==================== */
    const addPopupOpenHandler = () => {
        if(itemList !== null && itemList.data.length > 0) { childRef.current.onDismissHandler('export', true); }
    }
    /* ==================== 아이템복사하기 ==================== */
    /* ==================== 아이템삭제하기 ==================== */
    const ItemDeleteHandler = async() => {
        if(itemList !== null && itemList.data.length > 0) { 
            const result = await childRef.current.ItemDeleteArrayAPI(); 
            if(result) {
                let newList = [];
                itemList.data.filter((item) => { 
                    for(let s = 0; s < item.itemGroup.length; s++) {
                        if(!checkedItems.includes(item.itemGroup[s])) {
                            newList.push(item.itemGroup[s]); 
                        }
                    }
                });
                itemListGroup(newList);
            }
        }
    }
    /* ==================== 아이템삭제하기 ==================== */
    /* ==================== 아이템삭제하기 ==================== */
    const [ searchword,setSearchword ] = useState('');
    /* ==================== 아이템삭제하기 ==================== */
    
    return (
        <Element propRef={inViewRef} tag={`div`} className={styles.itemContainer}>
            <SubtitleArea title={'등록상품'} cnt={`${itemList === null ? 0 : itemList.size}건`}>
                <Element tag={`div`} className={styles.itemSearchwordArea}><input type="search" onChange={(e) => setSearchword(e.target.value)} /><FontAwesomeIcon icon="fa-light fa-magnifying-glass" /></Element>
                {(admin.LEVEL <= 1 || admin.LEVEL === 9) && (itemList !== null && itemList.data.length > 0) ? <Button anyprop={(e) => addPopupOpenHandler()} class={"subtitle"} icon="fa-light fa-copy" label={"복사하기"} ></Button> : null}
                {admin.LEVEL <= 1 && (itemList !== null && itemList.data.length > 0) ? <Button anyprop={(e) => ItemDeleteHandler()} class={"subtitle"} icon="fa-light fa-trash-can" label={"선택삭제"} ></Button> : null}
                {admin.LEVEL <= 2 ? <Link to={`/user/partner/${params.cd}/reg/item`} className={buttonStyles.subtitleButton}><FontAwesomeIcon icon="fa-light fa-circle-plus" /><Element tag={`span`}>등록하기</Element></Link> : null}
            </SubtitleArea>
            <Element tag={`div`} className={styles.itemListArea}>
                {itemList !== null && itemList.data.length > 0 ?
                    [...Array(4)].map((x, i) =>
                        <Element tag={`div`} key={i}>
                            {itemList.data.filter((filter) => filter.category == (i + 1)).length > 0 ?
                                itemList.data.filter((filter) => filter.category == (i + 1)).map((group) => (
                                    <React.Fragment key={i}>
                                        <List ref={childRef} list={group.itemGroup} checkedItems={checkedItems} setCheckedItems={setCheckedItems} category={(i + 1)} searchword={searchword} />
                                    </React.Fragment>
                                )) : (
                                    <React.Fragment key={i}>
                                        <List ref={childRef} list={null} checkedItems={checkedItems} setCheckedItems={setCheckedItems} category={(i + 1)} searchword={searchword} />
                                    </React.Fragment>
                                )}
                        </Element>
                    )
                    : <Element tag={`div`} className={styles.emptyData}><EmptyData /></Element>}
            </Element>
        </Element>
    );
}