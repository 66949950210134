import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
//modalPopup
import Modal from 'react-modal'
import 'src/asset/scss/plugin/modalPopup.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userMemberInfo.module.scss';
//comp
import FormArea from './PaymentCancelForm';//입력값

export default function PaymentCancel(props) {
    const params = useParams();
    //hook
    const { axiosPostHandler } = useAxiosHook();
    const { setOnMessageHandler, onNumberCommaFormat } = useComnHook();//submitPopupMessage, 숫자만입력(세자리자동콤마)
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem"
        }
    };
    const { isOpen, data } = props;//props
    const inputRef = useRef([]);//입력값ref
    /* ==================== 팝업창관리 ==================== */
    const onCloseHandler = () => {
        props.onDismiss('cancel', false, 1);
    }
    /* ==================== 팝업창관리 ==================== */
    /* ==================== 결제취소 ==================== */
    const onSubmitHandler = () => {
        if(!window.confirm("결제를 취소한 후에는 다시 복구할 수 없습니다.\n결제를 취소하시겠습니까?")) { return; }
        tosspaymentCancelAPI();
    }
    /* ==================== 결제취소 ==================== */
    
    //캔슬API
    const tosspaymentCancelAPI = async () => {
        const DATA = { MEMBER_CD:params.cd, PARTNER_CD:data.current.PARTNER_CD, ORDER_NO:data.current.ORDER_NO, CANCEL_PRICE:inputRef.current[0].value.replaceAll(",", "") };
        const result = await axiosPostHandler(`tosspaymentCancelForJson.do`, DATA);
        if(!result || (result.message !== "SUCCESS" && result.message !== "EXCEED_CANCEL_AMOUNT")) { setOnMessageHandler(2, "잘못된 요청입니다. 잠시 후 다시 시도해주세요."); return; }
        if(result.message = "EXCEED_CANCEL_AMOUNT") { setOnMessageHandler(2, "취소가능금액보다 적은 금액은 부분취소가 불가능합니다."); return; }
        if (result.message = "SUCCESS") {
            //props.memberReservListHandler();
            data.current.BALANCE_AMT = onNumberCommaFormat(new Number(data.current?.BALANCE_AMT?.replace(/,/g, '')) - new Number(inputRef.current[0].value?.replace(/,/g, '')));
            setOnMessageHandler(1, "정상 취소되었습니다.");
            props.onDismiss('cancel', false, 1);
        }
    }

    class NavTabs extends React.Component {
        state = { isDisabled: true, index: 0 };
        handleChange = (e, value) => {
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled: true });
        };
        onValidItem = (item, value) => {
            if (this.state.isDisabled != value) {
                this.setState({ ...this.state, isDisabled: value });
            }
        };
        render() {
            const { index } = this.state;

            return (
                <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles} ariaHideApp={false}>
                    <div className="popupContainer">
                        <div className="popupHeader"><h1>결제 취소</h1></div>
                        <div className="popupContainer">
                            <div className={styles.popupPaymentCancel}>
                                <label>취소가능금액</label>
                                <span>{data.current.BALANCE_AMT}원</span>
                                <FormArea inputRef={inputRef} data={data} onValid={this.onValidItem} />
                                {/* <input onChange={(e) => { onNumberCommaHandler(e); }}
                                ref={el => (inputRef.current = el)}
                                type="text" inputMode="numeric" placeholder="000,000원" /> */}
                            </div>
                        </div>
                        <div className="popupFooter">
                            <div className="btnArea">
                                <button onClick={(e) => onCloseHandler(false)} className="closeButton"><FontAwesomeIcon icon="fa-light fa-xmark" /><span>닫기</span></button>
                                <button disabled={this.state.isDisabled} onClick={(e) => onSubmitHandler()}
                                    className="submitButton"><span>확인</span></button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        }
    } return (<NavTabs />)
}