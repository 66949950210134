import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
//modalPopup
import Modal from 'react-modal';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userMemberInfo.module.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//Comp
import FormArea from './VehicleUpdateForm';//입력값

export default function VehicleUpdate(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem"
        }
    };
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//queryParameter
    /* ==================== 팝업창관리 ==================== */
    //팝업오픈핸들러
    const isOpen = props.isOpen;    
    const onCloseHandler = () => {
        props.onDismiss('update', false, 1);
    }
    /* ==================== 팝업창관리 ==================== */
    /* ==================== 주행거리 수정 ==================== */
    const data = props.data;
    const vehicleRef = useRef([]);//주행거리입력
    const onSubmitHandler = async() => {
        const KMLE = vehicleRef.current[0].value.replaceAll(",", "");
        const DATA = { MEMBER_CD:params.cd, ITEM_CD:data.current.ITEM_CD, KMLE:KMLE };
        const result = await axiosPostHandler(`memberVehicleUpdateForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { }
        if(result.message === "SUCCESS") { 
            data.current.KMLE = vehicleRef.current[0].value;
            onCloseHandler();
            props.onMessage(1, '주행거리 수정이 완료되었습니다.');
        }
    }
    /* ==================== 주행거리 수정 ==================== */

    class NavTabs extends React.Component {
        state = { isDisabled:true, index: 0 };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true }); 
        };
        onValidItem = (item, value) => {
            if(this.state.isDisabled != value) {
                this.setState({...this.state, isDisabled:value });
            }
        };
        render() { const { index } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles} ariaHideApp={false}>
                <div className="popupContainer">
                    <div className="popupHeader"><h1>주행거리 수정</h1></div>
                    <div className="popupContainer">
                        <div className={styles.popupVehicleUpdate}>
                            <label>기존주행거리</label>
                            <span>{data.current.KMLE}km</span>
                        </div>
                        <FormArea inputRef={vehicleRef} data={data} onValid={this.onValidItem} />
                    </div>
                    <div className="popupFooter">
                        <div className="btnArea">
                            <button onClick={(e) => onCloseHandler()} className="closeButton"><FontAwesomeIcon icon="fa-light fa-xmark" /><span>닫기</span></button>
                            <button disabled={this.state.isDisabled} onClick={(e) => onSubmitHandler()}
                                className="submitButton"><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </Modal>
        )}
    } return ( <NavTabs /> )
}