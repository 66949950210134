import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
//styles
import styles from 'src/asset/scss/styles/notify.module.scss';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import ActiveButtonWrapper from 'src/webapp/views/wrapper/ActiveButtonWrapper';
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import List from './notifyList/List';//알람발송목록
import NotifySample from './comn/NotifySample';//알림발송샘플확인

export default function NotifyList() {
    //stores
    const { admin } = useAdminStore();
    /* ==================== 샘플데이터출력 ==================== */
    //하위컴포넌트ref
    const childRef = useRef({});
    //제목 및 내용 샘플데이터전송
    const dataSampleHandler = (data) => {
        childRef.current.showSampleDataHandler(data);
    }
    /* ==================== 샘플데이터출력 ==================== */

    return ( 
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`알림 내역`}  dept={[{label:'알림'}, {label:'알림내역'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                {admin.LEVEL <= 1 ? 
                    <FixedPanel>
                        <ActiveButtonWrapper>
                            <Element tag={`div`} >
                                <Link to={'/notify/send'}><Element tag={`span`}>알림발송</Element></Link>
                            </Element>
                        </ActiveButtonWrapper>
                    </FixedPanel>
                : null}
                <FlickPanel className={styles.flickPanel}>
                    <Element tag={`div`} className={styles.notifyArea}>
                        <List onSample={dataSampleHandler} />
                        <NotifySample ref={childRef} />
                    </Element>
                </FlickPanel>
            </Container>
        </Section>
    );
}