import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//horizontalScroll
import ScrollContainer from 'react-indiana-drag-scroll';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
//store
import useAdminStore from 'src/webapp/stores/admin.store';
//hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//comp
import Element from 'src/webapp/views/wrapper/Element';//기본엘리먼트
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음
import ImgArea from 'src/webapp/views/component/ImgArea';//이미지컴포넌트
import PartnerItemExport from '../popup/PartnerItemExport';//아이템복사하기팝업

const List = forwardRef((props, ref) => {
    const isLock = useRef(false);
    const navigator = useNavigate();//화면이동
    const params = useParams();//파라미터

    //hook
    const { axiosPostHandler } = useAxiosHook();
    const { setOnMessageHandler } = useComnHook();
    //store
    const { admin } = useAdminStore();

    useImperativeHandle(ref, () => ({//상위컴포넌트에서 호출
        onDismissHandler,
        ItemDeleteArrayAPI
    }));
    //props
    let { list, category, checkedItems, setCheckedItems, searchword } = props;
    //카테고리필터
    const itemCategoryFilter = (element) => {
        switch (element) {
            case 1: return "엔진오일";
            case 2: return "타이어";
            case 3: return "정기점검";
            case 4: return "기타";
        }
    }
    category = itemCategoryFilter(props.category);//아이템카테고리
    /* ==================== 아이템선택(체크박스) ==================== */
    const onCheckedHandler = (e, item) => {
        const isChecked = e.target.checked;
        if(isChecked){
            setCheckedItems([...checkedItems, item]);
        } else{
            setCheckedItems(checkedItems.filter( (el) => el !== item));
        }
    }
    /* ==================== 아이템선택(체크박스) ==================== */
    /* ==================== 팝업오픈여부 ==================== */
    const [ isOpen,setOpen ] = useState({export:false});
    const onDismissHandler = (name, value) => {
        if(value && checkedItems.length <= 0) { alert("복사할 상품을 선택해주세요."); return; }
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        })
    }
    /* ==================== 팝업오픈여부 ==================== */
    /* ==================== 아이템삭제(체크박스) ==================== */
    const ItemDeleteArrayAPI = (async() => {
        if(isLock.current) {return;}
        isLock.current = true
        let DATA = [];
        for(let i = 0; checkedItems.length > i; i++){
            DATA.push({PARTNER_CD:params.cd, ITEM_CD:checkedItems[i].ITEM_CD, DEL_YN:"Y", LAST_UPDATE_USER:admin.COMN_ID});
        }
        if(DATA.length <= 0) { setOnMessageHandler(3, '1개 이상 데이터를 선택해주세요.'); return false; }

        if(!window.confirm("데이터 삭제 후 복구가 어려울 수 있습니다.\n해당데이터를 삭제하시겠습니까?")) { return false; }
        const result = await axiosPostHandler(`partnerItemDeleteArrayForAdmin.do`, DATA); 
        setTimeout(() => {isLock.current = false;}, 500)
        if(!result || result.message !== "SUCCESS") { return false; }
        if(!result) { setOnMessageHandler(2, "삭제가 완료되지 않았어요. 다시 시도해주세요."); return false; }
        if(result.message === "SUCCESS") {
            setOnMessageHandler(1, "삭제가 성공적으로 처리되었습니다.");
            return true;
        }
    })
    /* ==================== 아이템삭제(체크박스) ==================== */
    /* ==================== 검색어 + 데이터출력카운 ==================== */
    const listCnt = useRef(0);
    useEffect(() => {(async () => {
        if(list != null) {
            const cnt = list.filter((item) => item?.ITEM_NAME?.includes(searchword) || item?.DESCRIPTION.includes(searchword)).length;
            listCnt.current = cnt;
        }
    })();
    }, [list, searchword]);
    /* ==================== 검색어 + 데이터출력카운 ==================== */

    return (
        <>
        <Element tag={`div`} className={styles.subtitleArea}>
            <Element tag={`div`}>
                <Element tag={`h1`}>{category}<Element tag={`em`}>{list === null ? 0 : list.length}건</Element></Element>
            </Element>
        </Element>
        {list !== null ?
            <ScrollContainer horizontal={true} component={`ul`}>
                {listCnt.current > 0 ? list.filter((item) => item?.ITEM_NAME?.includes(searchword) || item?.DESCRIPTION?.includes(searchword)).map((item, i) =>
                    <Element tag={`li`} key={item.ITEM_CD}>
                        <Element tag={`div`} className={styles.itemState}>
                            <input type='checkbox' onClick={(e) => onCheckedHandler(e, item)} />{item.DEL_YN === 'Y' ? <Element tag={`span`}>삭제</Element> : null}
                        </Element>
                        <Element tag={`div`} anyprop={(e) => navigator(`/user/partner/${params.cd}/item/${item.ITEM_CD}`, { replace:false })}>
                            {item.IMG_SRC === undefined || item.IMG_SRC === null ? <Element tag={`div`} className={styles.imgNoneArea}><Element tag={`span`}>이미지없음</Element></Element> : <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.IMG_SRC} alt={item.ITEM_NAME} />}
                            <Element tag={`h1`}>{item.ITEM_NAME}</Element>
                            <Element tag={`h2`}>{item.DESCRIPTION}</Element>
                            <Element tag={`h3`}>{item.TOT_PRICE}원</Element>
                        </Element>
                    </Element>
                ) : null}
                {list.length <= 0 || listCnt.current <= 0 ? <Element tag={`div`} className={styles.emptyData}><EmptyData /></Element> : null}
            </ScrollContainer>
        : <Element tag={`div`} className={styles.emptyData}><EmptyData /></Element>}
        {isOpen.export ? <PartnerItemExport isOpen={isOpen.export} items={checkedItems} onDismiss={onDismissHandler} /> : null}
        </>
    )
})
export default List;