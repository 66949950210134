import React from 'react';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
import styles from 'src/asset/scss/styles/userMemberInfo.module.scss';
//hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//comp
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//입력폼
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//입력폼내제목

export default function Form(props) {
    const { data, inputRef } = props;//props
    const { onNumberCommaHandler } = useComnHook();//숫자만입력(세자리자동콤마)
    //validateCheck
    const onValidateHandler = () => {
        if(data !== null && (Number(inputRef.current[0].value.replace(/,/g, "")) <= Number(data.current.BALANCE_AMT.replace(/,/g, "")))) { props.onValid(null, false); return; }
        props.onValid(null, true);
    }

    return ( 
        <div className={["popupFormArea", styles.popupFormArea].join(" ")}>
            <FormWrapper>
                <TitleWrapper title={'취소 요청 금액'}/>
                <div className="textArea">
                    <input onChange={(e) => { onNumberCommaHandler(e); onValidateHandler()}}
                        ref={el => (inputRef.current[0] = el)}
                        type="text" inputMode="text" placeholder="취소할 금액을 입력하세요."
                        className="txtRight" />
                    <span>원</span>
                </div>
            </FormWrapper>
        </div>
    );
}