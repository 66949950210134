import React, { useRef } from "react";
//toggleSwitch
import Switch from "react-switch";
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";

export default React.memo(function SwitchToggle(props) {
  const item = props.item;
  //stores
  const { admin } = useAdminStore();
  const { axiosPostHandler } = useAxiosHook();//axios

  /* ==================== 차량모델수정(활성&비활성) ==================== */
  //차량모델수정API
  const isLock = useRef(false);
  const onChangeHandler = async(checked) => {
    if(isLock.current) { return; }
    const DATA = { MODEL_CD:item.MODEL_CD, DEL_YN:(!checked ? 'Y' : 'N'), LAST_UPDATE_USER:admin.COMN_ID };
    const result = await axiosPostHandler(`brandItemDeleteForJson.do`, DATA);
    setTimeout(() => { isLock.current = false; }, 500);
    if(!result) { return !checked; }
    if(result.message != "SUCCESS") { return !checked; }
    if(result.message == "SUCCESS") { return checked; } 
  }
  /* ==================== 차량모델수정(활성&비활성) ==================== */

  //TabsClass
	class SwitchToggle extends React.Component {
		constructor() {
      super();
      this.state = { checked:(item.DEL_YN === 'Y' ? false : true) };
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange = async(checked) => {
      const result = await onChangeHandler(checked);
      this.setState({checked:result});
    }

		render() {
			return (
				<label className={!this.state.checked ? null : "itemChecked"}>
          <Switch onChange={this.handleChange} checked={this.state.checked}
            width={40} height={20} 
            offColor='#737373' onColor='#FFD400' 
            handleDiameter={16} checkedIcon={false} uncheckedIcon={false}
            activeBoxShadow={''} />
        </label>
			)
		}
	}
	return (
		<SwitchToggle />
	)
})