import React from 'react';
//modalPopup
import Modal from 'react-modal';
import 'src/asset/scss/plugin/modalPopup.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//hook
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//현재단계
//comp
import Stepper from './PopupStepper';//현재단계
import Index01 from './ItemIndex01';//차량선택
import Index02 from './ItemIndex02';//차량명&이미지입력
import Index03 from './ItemIndex03';//제원정보입력

export default function ItemAdd(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
            height: "100%"
        }
    };
    const { childRef//하위컴포넌트
        , brandRef//브랜드정보ref
        , itemRef//차량정보ref
        , itemInfoRef//제원입력값ref
        , onCloseHandler//팝업닫기
        , brandModelOnSubmitAPI//브랜드모델 등록 및 수정 API
    } = useBrandHook();
    const { isOpen, data } = props;//props
    /* ==================== 브랜드모델 등록 ==================== */
    const onSubmitHandler = async () => {
        const result = await brandModelOnSubmitAPI(1, 0);//브랜드모델 등록 및 수정 API
        if (!result) { return; }
        await childRef.current.uploadBrandItemImageAPI(result, brandRef.current.BRAND_CD, 1);//브랜드모델이미지업로드
        onCloseHandler(props.onDismiss, "add");//팝업닫기
    }
    /* ==================== 브랜드모델 등록 ==================== */

    class NavTabs extends React.Component {
        state = { isDisabled: true, index: 0 };
        handleChange = (e, value) => {
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled: true, index: value });
        };
        onValidItem = (item, value) => {
            if(item != null) { brandRef.current = item; }
            if(this.state.isDisabled != value) {
                this.setState({ ...this.state, isDisabled:value });
            }
        };
        render() {
            const { index } = this.state;

            return (
                <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles} ariaHideApp={false}>
                    <Element tag={`div`} className="popupContainer">
                        <Element tag={`div`} className="popupHeader"><Element tag={`hi`}>차량 추가</Element>
                            <Stepper index={index} />
                        </Element>
                        <Element tag={`div`} className="popupContainer">
                            <Element tag={`div`}>
                                <Index01 isDisplay={index === 0 ? true : false} data={data} brand={brandRef} onValid={this.onValidItem} />
                                <Index02 isDisplay={index === 1 ? true : false} data={{}} inputRef={itemRef} choose={props.choose} onValid={this.onValidItem} ref={childRef} />
                                <Index03 isDisplay={index === 2 ? true : false} data={{}} inputRef={itemInfoRef} onValid={this.onValidItem} />
                            </Element>
                        </Element>
                        <Element tag={`div`} className="popupFooter">
                            <Element tag={`div`} className="btnArea">
                                <button onClick={(e) => onCloseHandler(props.onDismiss, "add")} className="closeButton"><FontAwesomeIcon icon="fa-light fa-xmark" /><span>닫기</span></button>
                                {index > 0 ?
                                    <button onClick={(e) => this.handleChange(e, (index - 1))}
                                        className="secondaryButton"><span>이전</span></button> : null}
                                <button disabled={this.state.isDisabled} onClick={(e) => index === 2 ? onSubmitHandler(1) : this.handleChange(e, (index + 1))}
                                    className="submitButton"><span>확인</span></button>
                            </Element>
                        </Element>
                    </Element>
                </Modal>
            )
        }
    } return (<NavTabs />)
}