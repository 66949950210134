import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
//modalPopup
import Modal from 'react-modal';
import 'src/asset/scss/plugin/modalPopup.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
//comp
import ItemIndex from './ItemIndex';//차량모델선택

export default function VehicleList(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
            height: "100%"
        }
    };
    const { axiosPostHandler } = useAxiosHook();//axios
    const { setOnMessageHandler } = useComnHook();//submitPopup메세지처리
    const params = useParams();//queryParameter
    const { isOpen, checkedItem, setCheckedItem } = props;//props
    /* ==================== 팝업오픈핸들러 ==================== */
    const onCloseHandler = () => {
        props.onDismiss('add', false);
    }
    /* ==================== 팝업오픈핸들러 ==================== */
    const onSubmitHandler = (newItems) => {
        setCheckedItem(newItems);
        props.onDismiss('add', false);
    }
    /* ==================== 파트너등록차량리스트 ==================== */
    //등록차량리스트API
    const [ vehicleList, setVehicleList ] = useState({list:[], option:[]});
    const partnerVehicleListAPI = async () => {
        const DATA = { PARTNER_CD:params.cd };
        const result = await axiosPostHandler(`partnerVehicleListByPartnerForAdmin.do`, DATA);
        if(!result || result.message !== "SUCCESS") { onCloseHandler(); setOnMessageHandler(3, '차량을 등록 후 이용해주세요.'); }
        if(result.message === "SUCCESS") {
            const data = result.data.filter(ele => ele.DEL_YN !== "Y");
            if(data.length <= 0) {
                onCloseHandler();
                setOnMessageHandler(3, '차량을 등록 후 이용해주세요.');
            } else {
                /* ===== 옵션그룹생성 ===== */
                const newList = data.reduce((prev, now) => {
                    if(!prev.some(obj => obj.value === now.BRAND_KOR_NAME)) {
                        const row = { name:now.BRAND_KOR_NAME, value:now.BRAND_KOR_NAME };
                        prev.push(row)
                    }
                    return prev;
                }, []);
                newList.unshift({name:'전체', value:''});
                setVehicleList({list:data, option:newList});
            }
        }
    }
    useEffect(() => {
        if(isOpen && vehicleList.list.length <= 0) { partnerVehicleListAPI(); }//등록차량리스트API
    }, [isOpen]);
    /* ==================== 파트너등록차량리스트 ==================== */

    class BoardUpdate extends React.Component {
        state = { isDisabled:true, checkedItems:[] };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true }); 
        };
        onValidItem = (value) => {
            console.log('checkedItem', value);
            this.setState({ isDisabled:value.length > 0 ? false : true, checkedItems:value });
            // if(this.state.isDisabled != value) {
            //     this.setState({...this.state, isDisabled:value });
            // }
        };
        render() { 
            const { isDisabled, checkedItems } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles} ariaHideApp={false}>
                <Element tag={`div`} className="popupContainer">
                    <Element tag={`div`} className="popupHeader"><Element tag={`h1`}>사용 차량 추가</Element></Element>
                    <Element tag={`div`} className="popupContainer">
                        <Element tag={`div`} className="popupFormArea">
                            <ItemIndex list={vehicleList} checkedItem={checkedItem} onValid={this.onValidItem} />
                        </Element>
                    </Element>
                    <Element tag={`div`} className="popupFooter">
                        <Element tag={`div`} className="btnArea">
                            <Button anyprop={(e) => onCloseHandler()} class="secondaryButton" label={"취소"} ></Button>
                            <Button anyprop={(e) => onSubmitHandler(checkedItems)} disabled={isDisabled} class="submitButton" label={"등록하기"} ></Button>
                        </Element>
                    </Element>
                </Element>
            </Modal>
        )}
    } return ( <BoardUpdate /> )
}