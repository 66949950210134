import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//styles
import styles from 'src/asset/scss/styles/userMembers.module.scss';

export default function Search(props) {
    //searchData
    const searchRef = props.search;
    //selectOption
    const option = [{name:'--옵션선택--', value:'ALL'}, {name:'회원명', value:1}, {name:'업체명', value:2}];
    const today = new Date().toISOString().slice(0, 10);//오늘날짜셋팅
    //엔터이벤트
    const handleKeydown = (e) => {
        if(e.key === "Enter") {
            props.onSearch(1, 1);
        }
    };

    return ( 
        <div className={styles.searchArea}>
            <div className="selectArea">
                <SelectSearch options={option} defaultValue={searchRef?.current[0]?.value === undefined ? option[0].value : searchRef?.current[0]?.value}
                    ref={el => (searchRef.current[0] = el)} onChange={el => (searchRef.current[0].value = el)}
                    placeholder="회원" />
            </div>
            <div className={styles.searchField}>
                <input ref={el => (searchRef.current[1] = el)} onKeyDown={handleKeydown}
                    type="text" placeholder="예약내역을 검색 해보세요" />
                <button><FontAwesomeIcon icon="fa-light fa-magnifying-glass" /></button>
            </div>
            <div className={styles.dateField}>
                <input type="date" onChange={(e) => props.onSearch(1, 1)}
                    ref={el => (searchRef.current[2] = el)}
                    defaultValue={today} />
                <span>-</span>
                <input type="date" onChange={(e) => props.onSearch(1, 1)} 
                    ref={el => (searchRef.current[3] = el)}
                    defaultValue={today} />
            </div>
        </div>
    );
}