import React from 'react';
//modalPopup
import Modal from 'react-modal';
import 'src/asset/scss/plugin/modalPopup.scss';
//hook
import useBoardHook from 'src/webapp/hooks/board/board.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
//comp
import ChildElement from "./Child";

export default function BoardAdd(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
        }
    };
    const { isOpen } = props;//popup on & off
    //hook
    const { inputRef//등록InputRef
        , boardNoticeRegAndUpdateAPI//공지사항등록API
    } = useBoardHook();
    /* ==================== popup on & off ==================== */
    //팝업오픈핸들러
    const onCloseHandler = (type) => {
        props.onDismiss('add', false, type);
    }
    /* ==================== popup on & off ==================== */
    /* ==================== 공지사항등록 ==================== */
    const boardRegHandler = async() => {
        const result = await boardNoticeRegAndUpdateAPI(null, 1);
        onCloseHandler(1);
    }
    /* ==================== 공지사항등록 ==================== */

    class BrandAdd extends React.Component {
        state = { isDisabled:true };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true }); 
        };
        onValidItem = (item, value) => {
            if(this.state.isDisabled != value) {
                this.setState({...this.state, isDisabled:value });
            }
        };
        render() { const { index } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles} ariaHideApp={false}>
                <Element tag={`div`} className="popupContainer">
                    <Element tag={`div`} className="popupHeader"><Element tag={`h1`}>공지 작성</Element></Element>
                    <Element tag={`div`} className="popupContainer">
                        <Element tag={`div`} className="popupFormArea">
                            <ChildElement inputRef={inputRef} data={null} onValid={this.onValidItem} />
                        </Element>
                    </Element>
                    <Element tag={`div`} className="popupFooter">
                        <Element tag={`div`} className="btnArea">
                            <Button anyprop={(e) => onCloseHandler(2)} class="secondaryButton" label={"취소"} ></Button>
                            <Button anyprop={(e) => boardRegHandler()} disabled={this.state.isDisabled} class="submitButton" label={"등록하기"} ></Button>
                        </Element>
                    </Element>
                </Element>
            </Modal>
        )}
    } return ( <BrandAdd /> )
}