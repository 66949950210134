import React from 'react';
import { Link } from 'react-router-dom';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//styles
import styles from 'src/asset/scss/styles/userMembers.module.scss';
//hooks
import useComnHook from "src/webapp/hooks/comn/comn.hook";
import useMemberHook from 'src/webapp/hooks/member/member.hook';
//comp
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음

export default function List({list, setMemberList}) {
    //hooks
    const { updateStateMemberAPI } = useMemberHook();//회원상태변경API
    const { setOnMessageHandler } = useComnHook();//메세지처리
    /* ==================== 회원상태옵션 ==================== */
    //상태option
    const option = [{name:'정상', value:'1'}, {name:'접속제한', value:'2'}, {name:'탈퇴', value:'9'}, {name:'삭제', value:'D'}];
    /* ==================== 회원상태옵션 ==================== */
    /* ==================== 회원상태변경 ==================== */
    //회원상태변경핸들러
    const updateMemberStateHandler = async(value, data, i) => {
        const result = await updateStateMemberAPI(value, data);
        if(result) { 
            updateFieldHandler('STATE', i, value);//데이터변경핸들러
            setOnMessageHandler(1, data.MEMBER_NAME + "님의 상태가 변경되었습니다.");//메세지처리
        }
    }
    //상태변경핸들러
    const updateFieldHandler = (name, index, value) => {
        let newArr = list.map((item, i) => {
            if(index === i && value !== null) { return { ...item, [name]: value }; } 
            return item;
        });
        setMemberList(newArr);
    };
    /* ==================== 회원상태변경 ==================== */

    return ( 
        <>
        {list.length > 0 ? 
        <div className={styles.listArea}>
            <ul>
                {list.map((item, i) => (
                    <li>
                        <div className={styles.imgArea}><FontAwesomeIcon icon="fa-thin fa-circle-user" /></div>
                        <div>
                            <Link to={item.STATE !== 4 ? `/user/member/${item.MEMBER_CD}` : null}><label style={{width: "5rem"}}>{item.MEMBER_NAME}</label></Link>
                        </div><hr/>
                        <div>
                            <Link to={item.STATE !== 4 ? `/user/member/${item.MEMBER_CD}` : null}><label style={{width: "5.2rem"}}>{item.NICK_NAME}</label></Link>
                        </div><hr/>
                        <div style={{width: "12rem"}}><span>{item.EMAIL_ADDR}</span></div><hr/>
                        <div style={{width: "7.5rem"}}><span>{item.TEL_NO}</span></div><hr/>
                        <div style={{width: "7.25rem"}} className={"listAreaSelectFilter"}>
                            <SelectSearch options={option} value={item.STATE} onChange={(e) =>  updateMemberStateHandler(e, item, i)} placeholder="상태" />
                        </div><hr/>
                        <div className={styles.stretch}><h5>{item.STATE == '2' ? '블랙리스트' : null}</h5></div>
                        <div className={styles.regDate}><FontAwesomeIcon icon="fa-light fa-calendar" /><span>{item.REG_DT}</span></div>
                    </li>
                ))}
            </ul> 
        </div>
        : <EmptyData /> }
        </>
    );
}