import React, { useState } from 'react';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//store
import useComnStore from 'src/webapp/stores/comn.store';

const useComnHook = () => {
    const { axiosPostHandler } = useAxiosHook();

    /* ==================== 토큰만료기한 설정(refhresh, access) ==================== */
    //refreshToken
    const refreshTokenExpires = () => {
        const now = new Date();
        const expires = new Date(now.setDate(now.getDate() + 1));
        return expires;
    }
    //accessToken
    const accessTokenExpires = () => {
        const now = new Date();
        const expires = new Date(now.setHours(now.getHours() + 1));
        return expires;
    }
    /* ==================== 토큰만료기한 설정(refhresh, access) ==================== */
    /* ==================== 입력validate ==================== */
    //숫자만입력
    const onNumberHandler = (e) => {
        let value = e.target.value;
        const nval = value.replace(/[^0-9]/g, "");
        e.target.value = nval.toString();
    }
    //숫자만입력(세자리콤마)
    const onNumberCommaHandler = (e) => {
        let value = e.target.value;
        console.log(value)
        let nval = Number(value.replaceAll(',', ''));
        if(!isNaN(nval)) { nval = nval.toLocaleString('ko-KR'); } 
        else { nval = ''; }
        //if(isNaN(nval)) { nval = 0;} 
        //else { nval = nval.toLocaleString('ko-KR'); }
        e.target.value = nval;
    };
    //숫자만입력(세자리콤마)
    const onNumberCommaFormat = (value) => {
        //let value = e.target.value;
        let nval = new Number(value.indexOf(',') != -1 ? value : value.replaceAll(",", ""));
        if(!isNaN(nval)) { nval = nval.toLocaleString('ko-KR'); } 
        else { nval = ''; }
        //if(isNaN(nval)) { nval = 0;} 
        //else { nval = nval.toLocaleString('ko-KR'); }
        return nval;
    };
    //콤마제거 및 타입변환
    const removeCommaHandler = (str) => {
        if (str == '' || str < 0) { return parseInt(0); }

        return parseFloat(str.toString().replace(/,/g, ''));
    }
    /* ==================== 입력validate ==================== */

    //상태필터
    const stateFilter = (type) => {//예약상태- 1.예약진행중, 2.예약완료, 3.입고완료, 4.점검완료, 5.이력등록완료, 6.수락지연취소, 7.미입고취소, 8.취소완료
        switch(type) {
            case "1" : return "예약진행중";
            case "2" : return "예약완료";
            case "3" : return "입고완료";
            case "4" : return "점검완료";
            case "5" : return "이력등록완료";
            case "6" : return "수락지연취소";
            case "7" : return "미입고취소";
            case "8" : return "취소완료";
            default : return "";
        }
    }

    /* ==================== 메세지팝업처리 ==================== */
    const { setMessage } = useComnStore();
    const setOnMessageHandler = (type, label) => {
        let icon = 0;
        switch(type) {
            case 1 : icon = 'submit'; break;
            case 2 : icon = 'error'; break;
            case 3 : icon = 'notice'; break;
        }
        setMessage({type:icon, label:label});
        setTimeout(() => { setMessage({type:null, label:null}); }, 1500);
    }
    /* ==================== 메세지팝업처리 ==================== */

    /* ==================== 공통코드호출 ==================== */
    const [ comnList, setComnList ] = useState([]);
    const comnListAPI = async (str) => {
        const DATA = { "COMN_CD":str };
        const result = await axiosPostHandler(`comnCdListForJson.do`, DATA);
        if(!result || result.message !== "SUCCESS") { setComnList([]); }
        if(result.message === "SUCCESS") { 
            setComnList(result.data); 
            console.log('comnList', result.data);
        }
    }
    /* ==================== 공통코드호출 ==================== */

    return { refreshTokenExpires, accessTokenExpires,
        setOnMessageHandler,
        onNumberHandler, onNumberCommaHandler, onNumberCommaFormat, removeCommaHandler,
        stateFilter,
        comnListAPI, comnList//공통코드호출
    };
}

export default useComnHook;