import React, { useEffect, useState } from 'react';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//styles
import styles from 'src/asset/scss/styles/userPartnerItemReg.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//입력폼
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//입력폼내제목

export default function ItemIndex(props) {
    const { list, checkedItem } = props;//props
    const [ selected,setSelected ] = useState('');//제조사셀렉트-선택된옵션
    const [ checkedItems,setCheckedItems ] = useState([]);//사용차량 - 선택된 아이템
    /* ==================== 아이템이벤트 ==================== */
    //아이템선택(체크박스)
    const onCheckedHandler = (e, newItem) => {
        const isChecked = e.target.checked;
        if(isChecked){
            //if(!checkedItems.some((item) => item.VEHICLE_CD === newItem.VEHICLE_CD)) {
                setCheckedItems([...checkedItems, newItem]);
            //}
        } else{
            setCheckedItems(checkedItems.filter((el) => el.VEHICLE_CD !== newItem.VEHICLE_CD));
        }
    }
    //아이템값출력필터
    const vehicleFilter = (item) => {
        return item.BRAND_KOR_NAME + " - " + item.MODEL_KOR_NAME;
    }
    /* ==================== 아이템이벤트 ==================== */
    /* ==================== 체크박스선택여부확인 ==================== */
    const isCheckedHandler = (item, type) => {//type-1.defaultChecked, 2.etc
        for(let i = 0; i < (type === 1 ? checkedItem.length : checkedItems.length); i++) {
            if((type === 1 ? checkedItem[i].VEHICLE_CD : checkedItems[i].VEHICLE_CD) === item.VEHICLE_CD) {
                return true;
            }
        }
        return false;
    }
    /* ==================== 체크박스선택여부확인 ==================== */
    /* ==================== validateCheck ==================== */
    const onValidateHandler = () => {
        if(checkedItems.length <= 0) { props.onValid(checkedItems); return; }
        props.onValid(checkedItems);
    }
    useEffect(() => {
        setCheckedItems(checkedItem);
        onValidateHandler();
    }, []);
    useEffect(() => {
        onValidateHandler();
    }, [checkedItems]);
    /* ==================== validateCheck ==================== */

    return ( 
        <Element tag={`div`} className={["popupFormArea", styles.popupFormArea].join(" ")}>
            {list.option.length > 0 ? 
            <Element tag={`div`} className={["formSelectArea", "themeLight" , "popupFormArea", styles.formSelectArea].join(" ")}><TitleWrapper title={'제조사'} />
                <SelectSearch options={list.option} value={list.option.value} 
                    defaultValue={list.option[0].value}
                    onChange={(e) => setSelected(e === null ? '' : e)} 
                    placeholder="제조사선택" />
            </Element>
            : null}
            <FormWrapper>
                <TitleWrapper title={'차종 (모델명)'}/>
                <Element tag={`div`} className={styles.radioArea}>
                    {list.list.length > 0 ? list.list.filter((item) => item?.BRAND_KOR_NAME?.includes(selected)).map((item, i) =>
                        <Element key={item.VEHICLE_CD} tag={`div`} className={["radio", "popupRadio", styles.radioWrap, (isCheckedHandler(item, 2) ? styles.selected : null)].join(" ")}>
                            <input type="checkbox" 
                                onChange={(e) => {onCheckedHandler(e, item);}} 
                                defaultChecked={isCheckedHandler(item, 1)} />
                            <Element tag={`label`}>{vehicleFilter(item)}</Element>
                        </Element>
                    ) : null}
                </Element>
            </FormWrapper>
        </Element>
    )
}