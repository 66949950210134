import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import styles from 'src/asset/scss/styles/userMemberInfo.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import SubtitleArea from 'src/webapp/views/wrapper/SubtitleArea';//서브타이틀공통컴포넌트
//comp
import List from './reservList/List';//예약리스트데이터
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

export default function ReservList(props) {
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//queryParameter
    /* ==================== 회원예약내역 ==================== */
    //회원예약내역리스트API
    const [ reservList,setReservList ] = useState([]);
    const pageRef = useRef(20);
    const isLock = useRef(false);
    const memberReservListAPI = async(type) => {//type-0.최초로딩, 1.무한로딩
        if(isLock.current) { return; } 

        isLock.current = true;
        const DATA = { MEMBER_CD:params.cd, ROWNUM:type === 0 ? 20 : pageRef.current };
        const result = await axiosPostHandler(`memberReservListByMemberForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { 
            if(pageRef.current === 20) { setReservList([]); }
            pageRef.current = null;
        }
        if(result.message === "SUCCESS") { 
            setReservList(pageRef.current === 20 ? result.data : reservList.concat(result.data));
            pageRef.current = result.data.length < 20 ? null : pageRef.current + 20;
        }
    }
    useEffect(() => {
        memberReservListAPI(0);//회원예약내역리스트API
    }, []);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { memberReservListAPI(1); }//알림리스트API
    }, [inView]);
    /* ==================== 회원예약내역 ==================== */

    return ( 
        <Element tag={`div`} className={styles.reservContainer}>
            <Element tag={`div`} className={styles.titleArea}>
                <SubtitleArea title={'이용 내역'} cnt={reservList.length + '건'}></SubtitleArea>
                </Element>
            <List memberReservListAPI={memberReservListAPI} ref={pageRef} list={reservList} />
            {reservList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
            : reservList.length > 0 && pageRef.current === null ? <InViewData readAll={true} /> 
            : null}
        </Element>
    );
}