import React, { forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import styles from 'src/asset/scss/styles/brandItemList.module.scss';
//Hook
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//차량모델이미지
import EmptyData from 'src/webapp/views/component/data/EmptyData';//리스트데이터none
import ItemToggle from './ItemToggle';//toggleSwitch
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

const ItemList = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({//상위컴포넌트에서 호출
        brandItemOnLoadHandler(item) {
            brandRef.current = item;
            brandItemListAPI(0, brandRef.current.BRAND_CD);
        }
        , brandItemDeleteAPI
    }));
    const { axiosPostHandler } = useAxiosHook();//axios
    //Hook
    const { setOnMessageHandler } = useComnHook();//submitPopup메세지처리
    const { pageRef//ROWNUM
        , scrollRef//elementScroll관리Ref
        , modelList//모델리스트
        , checkList//체크박스선택array
        , brandItemListAPI//제조사별모델리스트API
    } = useBrandHook();

    const brandRef = useRef(null);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { brandItemListAPI(1, brandRef.current.BRAND_CD); }//제조사별모델리스트API
    }, [inView]);

    /* ==================== 차량모델삭제 ==================== */
    const brandItemDeleteAPI = async() => {
        let DATA = [];
        for(let i = 0; i < checkList.current.length; i++) {
            if(checkList.current[i] != null) {
                const obj = JSON.parse(checkList.current[i].value);
                const MODEL_CD = obj.MODEL_CD;
                if(checkList.current[i].checked) {
                    const obj = { MODEL_CD:MODEL_CD };
                    DATA.push(obj);
                }
            }
        }
        if(DATA.length <= 0) { setOnMessageHandler(3, '1개 이상 데이터를 선택해주세요.'); return; } //메세지처리할것
        
        if(!window.confirm("데이터 삭제 후 복구가 어려울 수 있습니다.\n해당데이터를 삭제하시겠습니까?")) { return; }
        const result = await axiosPostHandler(`brandItemDeleteArrayForJson.do`, DATA);
        if(!result || result.message !== "SUCCESS") { setOnMessageHandler(2, '삭제가 완료되지 않았어요. 다시 시도해주세요.'); return; }
        if(result.message === "SUCCESS") { 
            setOnMessageHandler(1, '삭제가 성공적으로 처리되었습니다.');
            brandItemListAPI(0, brandRef.current.BRAND_CD);
        }
    }
    /* ==================== 차량모델삭제 ==================== */

	return (
        <Element tag={`div`} className={styles.brandItemListArea}>
            {modelList.length > 0 ? 
                <Element tag={`ul`} propRef={el => (scrollRef.current = el)}>
                    {modelList.map((item, i) => 
                        <Element tag={`li`} key={item.MODEL_CD}>
                            <input type='checkbox' ref={el => (checkList.current[i] = el)} value={JSON.stringify(item)} />
                            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.IMG_SRC} alt={item.BRAND_KOR_NAME} />  
                            <Element tag={`div`} style={{width: "13rem"}}>
                                <Element tag={`div`}><Element tag={`label`}>{item.MODEL_KOR_NAME}</Element><Element tag={`span`}>{item.MODEL_ENG_NAME}</Element></Element>
                            </Element><hr/>
                            <Element tag={`div`} style={{width: "5.5rem"}}><Element tag={`label`}>{item.INFO_1}</Element></Element><hr/>
                            <Element tag={`div`} className={styles.stretch}><Element tag={`label`}>{item.INFO_2 !== null && item.INFO_2 !== '-' ? (item.INFO_2 + 'cc') : '-' }</Element></Element>
                            <Element tag={`div`}>
                                <button onClick={(e) => props.onDismiss('update', true, item)}><FontAwesomeIcon icon="fa-light fa-pen-to-square" />수정하기</button></Element>
                            <Element tag={`div`} className={styles.toggleArea}><ItemToggle item={item}  /></Element>
                        </Element>
                    )}
                </Element>
            : <EmptyData />}
            {modelList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
            : modelList.length > 0 && pageRef.current === null ? <InViewData readAll={true} /> 
            : null}
        </Element>
	)
})
export default ItemList;