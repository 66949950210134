import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
//hook
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//store
import useAdminStore from 'src/webapp/stores/admin.store';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음

export default function List({list}) {
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();
    //store
    const { admin } = useAdminStore();
    //hook
    const { setOnMessageHandler } = useComnHook();//submitPopup Message
    //엘리먼트토글
    const [ toggle,setToggle ] = useState({});//리뷰내용toggle
    
    /* ==================== 리뷰삭제 ==================== */
    const isLock = useRef(false);//더블클릭방지
    //리뷰삭제API
    const partnerReviewDeleteAPI = async(item, i) => {
        if(!window.confirm("데이터를 삭제한 후에는 다시 복구할 수 없습니다.\n삭제하시겠습니까?") || isLock.current) { return; }

        isLock.current = true;
        const DATA = { SEQ_NO:parseInt(item.SEQ_NO), PARTNER_CD:params.cd, DEL_YN:'Y', LAST_UPDATE_USER:admin.COMN_ID};
        const result = await axiosPostHandler(`partnerReviewDeleteForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500)
        if(result.message !== "SUCCESS") {setOnMessageHandler(2, '삭제가 실패하였습니다.\n잠시후 다시 시도해주시기 바랍니다.')}
        if(result.message === "SUCCESS") {
            list[i].DEL_YN = "Y";
            setOnMessageHandler(1, '삭제가 완료되었습니다.');
        }
    };
    /* ==================== 리뷰삭제 ==================== */
    
    return ( 
        <>
        {list !== null && list.length > 0 ? 
            <Element tag={`ul`}>
                {list.map((item, i) =>
                    <Element tag={`li`} key={item.SEQ_NO}>
                        <Element tag={`div`}>
                            <Element tag={`div`} style={{width: "8.5rem"}}>
                                <Element tag={`label`}>{item.MEMBER_NAME}</Element>
                            </Element><hr/>
                            <Element tag={`div`} style={{width: "11.54rem"}} className={styles.dateTime}>
                                <Element tag={`span`} className={styles.dateTime}><FontAwesomeIcon icon="fa-light fa-clock" />{item.REG_DT}</Element>
                            </Element><hr/>
                            <Element tag={`div`} style={{width: "5.75rem"}} className={styles.starRate}>
                                {[...Array(5)].map((x, i) =>
                                    <FontAwesomeIcon icon={[(i < item.RATE ? "fa-solid" : "fa-light"), "fa-star"].join(" ")} />
                                )}
                            </Element><hr/>
                            <Element tag={`div`}  style={{width: "12.75rem"}}>
                                <Element tag={`span`}>{item.BRAND_KOR_NAME + " " + item.MODEL_KOR_NAME}</Element>
                            </Element>
                            {item.DEL_YN === "N" ? <Element tag={`div`}  className={styles.stretch}></Element>
                            : <><hr/><Element tag={`div`}  className={styles.stretch}><Element tag={`h5`}>삭제</Element></Element></>}
                            <Element tag={`div`}  style={{width: "52px"}}>
                                {item.DEL_YN === "N" ? <Element tag={`button`}><FontAwesomeIcon icon="fa-light fa-trash-can" /><Element anyprop={(e) => partnerReviewDeleteAPI(item, i)} tag={`span`}>삭제</Element></Element> 
                                : <Element tag={`button`}><FontAwesomeIcon icon="fa-light fa-trash-can-undo" /><Element tag={`span`}>취소</Element></Element>}
                            </Element>
                            <a className={item.REVIEW === undefined ? styles.none : null} onClick={(e) => setToggle(toggle === item ? {} : item.REVIEW !== undefined ? item : toggle)}>
                                <FontAwesomeIcon icon={["fa-light", toggle === item ? "fa-chevron-up": "fa-chevron-down"].join(" ")} />
                            </a>
                        </Element>
                        {toggle === item ? 
                        <Element tag={`article`}>
                            <pre dangerouslySetInnerHTML={{__html: item.REVIEW}}></pre>
                        </Element>
                        : null}
                    </Element>
                )}
            </Element>
        : <Element tag={`div`} className={styles.emptyData}><EmptyData/></Element> }
        </>
    );
}