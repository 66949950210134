import React, { forwardRef, useImperativeHandle, useRef } from 'react';
//styles
import styles from 'src/asset/scss/styles/notify.module.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//Comp
import Form from './Form';//알림발송작성
import NotifySample from '../comn/NotifySample';//알림발송샘플확인

const NotifysendWrapper = forwardRef((props, ref) => {
    //상위컴포넌트에서 호출
    useImperativeHandle(ref, () => ({
        setMessageHandler
    }));
    const { axiosPostHandler } = useAxiosHook();//axios
    //hooks
    const { setOnMessageHandler } = useComnHook();
    //stores
    const { admin } = useAdminStore();

    //validateCheck
    const onValidateHandler = () => {
        for(let i = 0; i < inputRef.current.length; i++) {
            if(inputRef.current[i].value.length <= 0) { props.onValid(null, true); return; } 
        }
        props.onValid(null, false);
    }

    /* ==================== 메세지발송 ==================== */
    const isLock = useRef(false);
    const inputRef = useRef([]);//입력데이터
    const receiverListRef = useRef([]);//지정 수신자목록
    const setMessageHandler = async() => {
        if(isLock.current) { return; }
        const SUBJECT        = inputRef.current[0].value;
        const CONTENT        = inputRef.current[1].value;
        const TYPE           = inputRef.current[2].value === 'on' ? 1 : Number(inputRef.current[2].value);
        const RECEIVE_TYPE   = inputRef.current[3].value === 'on' ? 1 : Number(inputRef.current[3].value);
        const RECEIVER       = inputRef.current[4].value === 'on' ? 1 : Number(inputRef.current[4].value);

        let DATA = null;
        let URL = null;
        if(RECEIVER === 1 || RECEIVER === 2) {//멤버스 전체, 파트너스 전체
            DATA = {SUBJECT:SUBJECT, CONTENT:CONTENT, TYPE:TYPE, RECEIVE_TYPE:RECEIVE_TYPE, RECEIVER:RECEIVER, REG_ID:admin.COMN_ID };
            URL = `sendMessageListAllForAdmin.do`;
        }
        if(RECEIVER === 3) {//지정수신자
            DATA = [];
            URL = `sendMessageArrayListForAdmin.do`;
            for(let i = 0; i < receiverListRef.current.length; i++) {
                const ele = receiverListRef.current[i];
                const RECEIVER_NAME  = RECEIVER === 3 ? (ele.MEMBER_NAME ?? ele.PARTNER_NAME) : null;
                const MEMBER_CD      = ele.MEMBER_CD !== undefined && ele.MEMBER_CD !== null ? ele.MEMBER_CD : 0;
                const PARTNER_CD     = ele.PARTNER_CD !== undefined && ele.PARTNER_CD !== null ? ele.PARTNER_CD : 0;
                const obj = { SUBJECT:SUBJECT, CONTENT:CONTENT, TYPE:TYPE, RECEIVE_TYPE:RECEIVE_TYPE, RECEIVER:RECEIVER, REG_ID:admin.COMN_ID, FCM_TOKEN:ele.FCM_TOKEN, RECEIVER_NAME:RECEIVER_NAME, MEMBER_CD:MEMBER_CD, PARTNER_CD:PARTNER_CD };
                DATA.push(obj);
            } 
        }
        const result = await axiosPostHandler(URL, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result || result.message !== "SUCCESS") { setOnMessageHandler(2, "메세지가 전송되지 않았습니다.확인 후 다시 시도해주세요."); return;  }
        // if(!result) { return; }
        // if(result.message != "SUCCESS") { return !checked; }
        if(result.message === "SUCCESS") { setOnMessageHandler(1, (RECEIVER === 1 ? "멤버스 그룹" : RECEIVER === 2 ? "파트너스 그룹" : RECEIVER === 3 ? "지정 수신자" : null) + " 메세지를 발송 요청했습니다."); } 
    }
    /* ==================== 메세지발송 ==================== */
    /* ==================== 미리보기샘플 ==================== */
    //하위컴포넌트ref
    const childRef = useRef({});
    //제목 및 내용 샘플데이터전송
    const dataSampleHandler = (name, value) => {
        childRef.current.addSampleDataHandler(name, value);
    }
    /* ==================== 미리보기샘플 ==================== */

    return ( 
        <Element tag={`div`} className={styles.notifyArea}>
            <Form inputRef={inputRef} receiverListRef={receiverListRef} onSample={dataSampleHandler} onValid={onValidateHandler} />
            <NotifySample ref={childRef} />
        </Element>
    );
})
export default NotifysendWrapper;