import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//horizontalScroll
import ScrollContainer from 'react-indiana-drag-scroll';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//styles
import styles from 'src/asset/scss/styles/userPartnerItemReg.module.scss';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useUploadFileHook from 'src/webapp/hooks/comn/uploadFile.hook';
//store
import useAdminStore from 'src/webapp/stores/admin.store';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//입력폼
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//입력폼내제목
//comp
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
import VehicleListPopup from '../popup/VehicleList';//팝업-등록차량선택

export default function Form(props) {
    const params = useParams();
    const itemRef = useRef([]);
    //hook
    //파일추가
    const { comnListAPI//공통코드호출API
        , comnList//공통코드
        , onNumberCommaHandler//세자리콤마핸들러
        , removeCommaHandler//콤마제거 및 타입변환
    } = useComnHook();
    //파일추가
    const { files, setFiles//fileuseState
        , MAX_COUNT//최대업로드갯수
        , addFileHandler//파일업로드핸들러
    } = useUploadFileHook();
    //store
    const { admin } = useAdminStore();

    useEffect(() => {
        (async () => {
            comnListAPI("T");
        })();
    }, []);
    /* ============================ 사용차량추가 팝업호출 ============================ */
    const [isOpen, setOpen] = useState({ add: false });
    const onDismissHandler = (name, value) => {
        setOpen((prevState) => {
            return { ...prevState, [name]: value }
        })
    }
    /* ============================ 사용차량추가 팝업호출 ============================ */
    /* ============================ 사용차량삭제 ============================ */
    const [checkedItem, setCheckedItem] = useState([]);
    const deleteCheckedHandler = (item) => {
        const newList = checkedItem.filter(ele => ele !== item);
        setCheckedItem(newList);
    }
    /* ============================ 사용차량삭제 ============================ */
    /* ============================ 카테고리맵옵션 ============================ */
    const [category, setCategory] = useState(1)
    const categoryArray = [{ name: '엔진오일', value: 1 }, { name: '타이어', value: 2 }, { name: '정기점검', value: 3 }, { name: '기타', value: 4 }];
    /* ============================ 카테고리맵옵션 ============================ */
    /* ============================ 입력값 ============================ */
    const dataFilter = (i, type) => {
        switch (i) {
            case 0: return type === 0 ? '제품명' : null;
            case 1: return type === 0 ? '설명' : null;
            case 2: return type === 0 ? '단가' : null;
            case 3: return type === 0 ? '기술료' : null;
            case 4: return type === 0 ? '작업시간' : null;
            case 5: return type === 0 ? '타이어 구분' : null;
            case 6: return type === 0 ? '오일필터명' : null;
            case 7: return type === 0 ? '오일단위' : null;
            case 8: return type === 0 ? '오일수' : null;
            case 9: return type === 0 ? '오일필터가격' : null;
            case 10: return type === 0 ? '총 가격' : null;
        }
    }
    //총금액
    const setOnTotalPriceHandler = () => {//(단가 * 오일갯수(오일카테고리아닐시 default1)) + 오일필터단가 + 기술료
        console.log("발동")
        if (itemRef.current.length <= 0) { return; }

        const price = removeCommaHandler(itemRef.current[2]?.value ? itemRef.current[2].value : 0);
        const royalty = removeCommaHandler(itemRef.current[3]?.value ? itemRef.current[3].value : 0);
        const oilPrice = removeCommaHandler(itemRef.current[9]?.value ? itemRef.current[9].value : 0);
        const oilQuota = removeCommaHandler(itemRef.current[8]?.value ? itemRef.current[8].value : 1);
        const totPrice = Math.round((price * oilQuota) + oilPrice + royalty);//합금액 소숫점버림
        console.log(price, royalty, oilPrice, oilQuota)
        console.log(totPrice, itemRef.current[10].value)
        itemRef.current[10].value = totPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    //세자리콤마(소수점)핸들러
	const addComma = (e) => {
		//숫자, .만 입력받는식
		let inputValue = e.target.value.replace(/[^0-9.]/g, "");
		const decimal = inputValue.split("."); // 마침표(.) 단위로 배열 쪼개기

		// 소수점 이전에만 1000단위 마다 comma 추가를 적용
		let formattedNumber = decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		// 소수점을 찍지 않았을 때 두번째 배열 요소는 undefined인데, 
		// 만약 두번째 요소가 undefined가 아닌 경우
		if (decimal[1] !== undefined) {

			// 배열의 첫번째 정수는 정수 자신에서 마침표와 소수 이후의 값들을 모두 결합
			formattedNumber = formattedNumber + "." + decimal[1];
		}
		e.target.value = formattedNumber;
	}

    /* ============================ 입력값 ============================ */
    /* ============================ 등록하기 ============================ */
    //오일단위SelectOption 초기셋팅
    const [unit, setUnit] = useState([{ name: "개수(단위:1개)", value: 1 }, { name: "ml(단위:100ml) ", value: 2 }]);//오일갯수
    //엔터이벤트핸들러
    const onSubmitHandler = async() => {
        // props.onClear();//데이터리셋을 위한 상위컴포넌트 리렌더링
        const result = await inputValidateHandler();
        if (!result) {return;}
        partnerItemRegAndUpdate(1);
        // console.log(itemRef.current.map(item => item?.value));
        // console.log(itemRef.current);
        // console.log(checkedItem.map(item => item.MODEL_CD))
    }

    //등록검증핸들러
    const inputValidateHandler = () => {
        for (let i = 0; i < itemRef.current.length; i++) {
            const data = itemRef.current[i] ?? null;
            if (data == null) { continue; }

            let val = null;
            switch (i) {
                case 3: val = data?.value ?? 0; break;
                case 4: val = data?.value ?? 1; break;
                case 5: val = data.checked; break;
                case 7: val = data?.value ?? 1; break;
                case 10: val = data ?? 0; break;
                default: val = data?.value;
            }
            if (val.length === 0 && (i === 0 || i === 1 || i === 2)) { alert("제품명, 설명, 단가를 입력해주세요."); return false;}
            if (category === 1 && val.length === 0 && (i === 6 || i === 8 || i === 9)) { alert("오일명, 오일수, 오일가격을 입력해주세요."); return false; }
        }
        return true;
    }
    const partnerItemRegAndUpdate = async(type, item, seq) => {// type - 1.등록 2.수정
        let DATA = [];
        for (let i = 0; checkedItem.length > i; i++) {
            if (checkedItem[i].VEHICLE_CD != null) {
                DATA.push({
                    "PARTNER_CD": params.cd, "ITEM_CD": type == 1 ? 0 : item.ITEM_CD, "CATEGORY": category
                    , "VEHICLE_CD": checkedItem[i].MODEL_CD
                    , "ITEM_NAME": itemRef.current[0].value
                    , "DESCRIPTION": itemRef.current[1] === undefined ? item.DESCRIPTION ?? "" : itemRef.current[1].value
                    , "PRICE": removeCommaHandler(itemRef.current[2].value)
                    , "ROYALTY": itemRef.current[3].value.length === 0 ? 0 : removeCommaHandler(itemRef.current[3].value)
                    , "DURATION_TIME": itemRef.current[4].value ?? item.DURATION_TIME ?? 1
                    , "TIRE_FR": category === 2 ? (itemRef.current[5].checked ? 1 : 2) : 0
                    , "OIL_NAME": category === 1 ? itemRef.current[6].value : ""
                    , "OIL_UNIT": category === 1 ? itemRef.current[7].value : 0
                    , "OIL_QUOTA": category === 1 ? itemRef.current[8].value ?? 1 : 0
                    , "OIL_PRICE": category === 1 ? itemRef.current[9].value.length == 0 ? 0 : removeCommaHandler(itemRef.current[4].value) : -1
                    , "TOT_PRICE": removeCommaHandler(itemRef.current[10].value)
                    , "IMG_CD": seq[0] ?? 0
                    , SUBMIT_TYPE: type
                    , LAST_UPDATE_USER: admin.COMN_ID
                });
            }
        }
        console.log(DATA);
    }
    /* ============================ 등록하기 ============================ */

    class Form extends React.Component {
        state = { index: null };
        // state = { disabled: true, index: null };
        handleChange = (e, value) => {
            this.setState({ ...this.state, index: value });
        };

        render() {
            const { index, disabled } = this.state;

            return (
                <>
                    <Element tag={`div`} className={styles.formContainer}>
                        <Element tag={`div`} className={styles.formArea}>
                            {/* START 이미지 추가 */}
                            {files.length < MAX_COUNT.current ?
                                <Element tag={`div`} className={styles.fileArea}>
                                    <Element tag={`label`} htmlFor="file"><Element tag={`span`}>이미지 업로드</Element><FontAwesomeIcon icon="fa-light fa-rectangle-history-circle-plus" /></Element>
                                    <input onChange={(e) => addFileHandler(e)} type="file" id="file" accept='image/webp' />
                                </Element>
                                : null}
                            {files.length > 0 && files.map((file, idx) => (
                                <Element tag={`div`} className={styles.imgPreview}>
                                    <Element tag={`div`} className={styles.previewImage}><img src={file.url} alt="preview-img" /></Element>
                                    <Button anyprop={(e) => setFiles([])} icon={`fa-light fa-pen-to-square`} label={`이미지 변경하기`} />
                                </Element>
                            ))}
                            {/* END 이미지 추가 */}
                            {/* START 사용차량추가 */}
                            <FormWrapper>
                                <TitleWrapper title={'사용 차량 :'} />
                                <ScrollContainer horizontal={true} component={`ul`} className={styles.vehicleListArea}>
                                    {checkedItem.map((item, i) =>
                                        <Element tag={`li`} key={i}><Element tag={`label`}>{item.BRAND_KOR_NAME + ' - ' + item.MODEL_KOR_NAME}</Element><Button anyprop={(e) => deleteCheckedHandler(item)} icon={"fa-light fa-xmark"} /></Element>
                                    )}
                                </ScrollContainer>
                                <Button anyprop={(e) => onDismissHandler('add', true)} icon={"fa-light fa-circle-plus"} label={`사용 차량 추가하기`} />
                            </FormWrapper>
                            {/* END 사용차량추가 */}
                            {/* START 카테고리선택 */}
                            <FormWrapper>
                                <TitleWrapper title={'카테고리'} />
                                <Element tag={`div`} className={styles.radioArea}>
                                    {categoryArray.map((item, i) =>
                                        <Element tag={`div`} className={["radio", styles.radio].join(" ")}>
                                            <input name="category" id={'CATEGORY_TYPE' + item.value} type="radio"
                                                onChange={(e) => setCategory(item.value)}
                                                checked={category === item.value ? true : false} />
                                            <label htmlFor={'CATEGORY_TYPE' + item.value} className="radio-label">{item.name}</label>
                                        </Element>
                                    )}
                                </Element>
                            </FormWrapper>
                            {/* END 카테고리선택 */}
                            {/* START 입력필드 */}
                            {/* 제품명 ~ 작업시간 */}
                            {[...Array(5)].map((x, i) =>
                                i !== 4 ?
                                    <FormWrapper key={i}>
                                        <TitleWrapper title={dataFilter(i, 0)} />
                                        {i === 2 || i === 3 ? (<input type="text" inputMode="numeric" placeholder="000,000원" ref={el => (itemRef.current[i] = el)} onChange={(e) => { onNumberCommaHandler(e); setOnTotalPriceHandler(); }} />) : (<input type="text" ref={el => (itemRef.current[i] = el)} />)}
                                    </FormWrapper>
                                    : i === 4 && comnList.length > 0 ?
                                        <Element tag={`div`} className={["formSelectArea", styles.formSelectArea].join(" ")}>
                                            <TitleWrapper title={dataFilter(i, 0)} />
                                            <SelectSearch options={comnList} value={comnList.value}
                                                defaultValue={comnList[0].value}
                                                ref={el => (itemRef.current[i] = el)}
                                                onChange={(e) => { (itemRef.current[4].value = e); }}
                                                placeholder="작업시간선택" />
                                        </Element>
                                        : null
                            )}
                            {/* 타이어구분 또는 오일명 ~ 오일가격 */}
                            {[...Array(4)].map((x, i) =>
                                category === 1 ?
                                    <FormWrapper key={i}>
                                        <TitleWrapper title={dataFilter((i + 6), 0)} />
                                        {i + 6 === 8 || i + 6 == 9 ? (<input ref={el => (itemRef.current[i + 6] = el)} type="text" inputMode='numeric' onChange={(e) => {i + 6 == 8 ? addComma(e) : onNumberCommaHandler(e); setOnTotalPriceHandler();}} />) :
                                            i + 6 === 7 ? (<Element tag={`div`} className={["formSelectArea", styles.formSelectArea].join(" ")}>
                                                <SelectSearch options={unit} value={unit.value}
                                                    defaultValue={unit[0].value}
                                                    ref={el => (itemRef.current[7] = el)}
                                                    onChange={(e) => { (itemRef.current[7].value = e); }}
                                                    placeholder="오일단위선택" />
                                            </Element>) :
                                                (<input type="text" inputMode='numeric' onChange={(e) => { }} ref={el => (itemRef.current[i + 6] = el)} />)}
                                    </FormWrapper>
                                    : category === 2 && i === 0 ?
                                        <FormWrapper key={i}>
                                            <TitleWrapper title={dataFilter(5, 0)} />
                                            <Element tag={`div`} className={styles.radioArea}>
                                                <Element tag={`div`} className={["radio", styles.radio].join(" ")}>
                                                    <input name="tire" id={'TIRE_FR1'} type="radio"
                                                        // onChange={(e) => setCategory(item.value)}
                                                        ref={el => (itemRef.current[5] = el)}
                                                        defaultChecked={true} />
                                                    <label htmlFor={'TIRE_FR1'} className="radio-label">앞</label>
                                                </Element>
                                                <Element tag={`div`} className={["radio", styles.radio].join(" ")}>
                                                    <input name="tire" id={'TIRE_FR2'} type="radio"
                                                        defaultChecked={false} />
                                                    <label htmlFor={'TIRE_FR2'} className="radio-label">뒤</label>
                                                </Element>
                                            </Element>
                                        </FormWrapper>
                                        : null
                            )}
                            {/* 총가격 */}
                            <FormWrapper>
                                <TitleWrapper title={dataFilter(10, 0)} />
                                <input ref={el => (itemRef.current[10] = el)} type="text" inputMode="numeric" placeholder="000,000원" defaultValue={''} readOnly />
                            </FormWrapper>
                            {/* END 입력필드 */}
                        </Element>
                        {/* START 버튼 */}
                        <Element tag={`div`} className={styles.btnArea}>
                            <Link to={-1}><FontAwesomeIcon icon="fa-light fa-chevron-left" /><Element tag={`span`}>이전으로</Element></Link>
                            <Button anyprop={(e) => onSubmitHandler()} icon={"fa-light fa-floppy-disk"} label={`등록하기`} />
                        </Element>
                        {/* END 버튼 */}
                    </Element>
                    {isOpen.add ? <VehicleListPopup isOpen={isOpen.add} checkedItem={checkedItem} setCheckedItem={setCheckedItem} onDismiss={onDismissHandler} /> : null}{/* 사용차량등록팝업 */}
                </>
            )
        }
    } return (<Form />)
}