import React, { useState, useRef } from 'react';
//styles
import styles from 'src/asset/scss/styles/notify.module.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import ActiveButtonWrapper from 'src/webapp/views/wrapper/ActiveButtonWrapper';
import ComnDiv from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import NotifySendWrapper from './notifySend/NotifySendWrapper';//알림발송작성
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//submitPopupMessage

export default function NotifySend() {
    //stores
    const { admin } = useAdminStore();

    const [ resetCnt,setResetCnt ] = useState(0);//입력폼초기화
    /* ==================== 메세지전송 ==================== */
    //하위컴포넌트ref
    const childRef = useRef({});
    //메세지발송핸들러
    const onSubmitHandler = () => {
        childRef.current.setMessageHandler();
    }
    /* ==================== 메세지전송 ==================== */

    class NotifySend extends React.Component {
        state = { isDisabled:true };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true }); 
        };
        onValidHandler = (item, value) => {
            if(this.state.isDisabled != value) {
                this.setState({...this.state, isDisabled:value });
            }
        };
        render() { const { index } = this.state;

    return ( 
        <>
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`알림 발송`}  dept={[{label:'알림'}, {label:'알림발송'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FlickPanel className={styles.flickPanel}>
                    <NotifySendWrapper key={resetCnt} ref={childRef} onValid={this.onValidHandler} />
                </FlickPanel>
                {admin.LEVEL <= 1 ? 
                <FixedPanel>
                    <ActiveButtonWrapper>
                        <ComnDiv tag={`div`} >
                            <button onClick={(e) => setResetCnt(resetCnt + 1)}><FontAwesomeIcon icon="fa-light fa-rotate-right" /><span>초기화</span></button>
                            <button onClick={(e) => onSubmitHandler()} disabled={this.state.isDisabled}><FontAwesomeIcon icon="fa-light fa-paper-plane-top" /><span>발송하기</span></button>
                        </ComnDiv>
                    </ActiveButtonWrapper>
                </FixedPanel>
                : null}
            </Container>
        </Section>
        <SubmitPopup />
        </>
        )}
    } return ( <NotifySend /> )
}