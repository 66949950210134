import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import ActiveButtonWrapper from 'src/webapp/views/wrapper/ActiveButtonWrapper';
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import BrandList from './BrandList';//제조사리스트
import ItemList from './ItemList';//제조사별차량리스트
import ItemAdd from './popup/ItemAdd';//제조사별차량추가
import ItemUpdate from './popup/ItemUpdate';//제조사별차량수정
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//SubmitPopup컴포넌트

export default function MemberList() {
    //stores
    const { admin } = useAdminStore();
    //브랜드선택핸들러
    const childRef = useRef({});
    const brandOnChangeHandler = (item) => {
        childRef.current.brandItemOnLoadHandler(item);
    }
    //선택아이템삭제핸들러
    const brandOnDeleteHanelr = () => {
        childRef.current.brandItemDeleteAPI();
    }
    /* ============================ 모델등록 및 수정 팝업호출 ============================ */
    const [ isOpen,setOpen ] = useState({add:false, update:false});
    const dataRef = useRef({});//선택된데이터객체
    const onDismissHandler = (name, value, item) => {
        if(value) { dataRef.current = item; }//데이터셋
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        })
    }
    /* ============================ 모델등록 및 수정 팝업호출 ============================ */

    return ( 
        <>
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`차량 데이터 관리`}  dept={[{label:'기타'}, {label:'차량 데이터 관리'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FixedPanel>
                    <ActiveButtonWrapper>
                        <Element tag={`div`} >
                            <Link to={'/brand/list'}><span>제조사 관리</span></Link>
                            {admin.LEVEL <= 1 ? <button onClick={(e) => onDismissHandler('add', true, null)}><span>차량 추가</span></button> : null}
                        </Element>
                        <Element tag={`div`} >
                            {admin.LEVEL <= 2 ? <button onClick={(e) => brandOnDeleteHanelr()} className="btnDelete"><span>선택 삭제</span></button> : null}
                        </Element>
                    </ActiveButtonWrapper>
                </FixedPanel>
                <FixedPanel>
                    <BrandList choose={brandOnChangeHandler} />
                </FixedPanel>
                <FlickPanel>
                    <ItemList ref={childRef} onDismiss={onDismissHandler} />
                </FlickPanel>
            </Container>
        </Section>
        {isOpen.add ? <ItemAdd isOpen={isOpen.add} data={dataRef} choose={brandOnChangeHandler} onDismiss={onDismissHandler} /> : null}{/* 모델등록 */}
        {isOpen.update ? <ItemUpdate isOpen={isOpen.update} data={dataRef} choose={brandOnChangeHandler} onDismiss={onDismissHandler} /> : null}{/* 모델수정 */}
        <SubmitPopup />
        </>
    );
}