import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/boardList.module.scss';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useBoardHook from 'src/webapp/hooks/board/board.hook';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
//popup
import BoardAdd from './popup/BoardAdd';//공지사항등록팝업
import BoardUpdate from './popup/BoardUpdate';//공지사항수정팝업

const List = forwardRef((props, ref) => {
    //hook
    const { boardNoticeRegAndUpdateAPI } = useBoardHook();
    const { setOnMessageHandler } = useComnHook();//메세지전달
    const { list, setBoardList } = props;
    useImperativeHandle(ref, () => ({//상위컴포넌트에서 호출
        addPopupOpenHandler() {
            onDismissHandler('add', true, null);
        }
    }));
    /* ==================== 공지사항삭제 ==================== */
    const boardDeleteAPI = async(item) => {
        if(!window.confirm("데이터를 삭제한 후에는 다시 복구할 수 없습니다.\n삭제하시겠습니까?")) { return; }
        const result = await boardNoticeRegAndUpdateAPI(item, 3);
        if(!result) { return; }
        if(result) { 
            setBoardList(list.filter(board => board.SEQ_NO !== item.SEQ_NO));
            setOnMessageHandler(1, '삭제가 완료되었습니다.');
        }
    }
    /* ==================== 공지사항삭제 ==================== */
    /* ==================== 공지사항등록 및 수정 ==================== */
    //팝업오픈여부
    const [ isOpen,setOpen ] = useState({add:false, update:false});
    const dataRef = useRef({});
    const onDismissHandler = (name, value, item) => {//item- 1:데이터처리후닫기, 2:취소
        if(value) { dataRef.current = item !== null ? item : {}; }
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        })
        if(item === 1 && name === 'add') {//데이터reload
            props.onLoad(3);
        }
    }
    /* ==================== 공지사항등록 및 수정 ==================== */
    const [ toggle,setToggle ] = useState(null);//공지내용토글

    return ( 
        <>
        <Element tag={`div`} className={styles.boardListContainer}>
            <Element tag={`ul`}>
                {list.length > 0 && list.map((item, i) =>
                    <Element key={item.SEQ_NO} tag={`li`}>
                        <Element tag={`div`} className={toggle === item ? styles.selected : null}>
                            <Element tag={`div`} className={styles.title}>
                                <Element tag={`div`}><Element tag={`h2`} style={{width:'25rem'}}>{item.SUBJECT}</Element></Element>
                                <Element tag={`div`} className={styles.btnArea}>
                                    <Button anyprop={(e) => boardDeleteAPI(item)} class={"subtitle"} icon="fa-light fa-trash-can" label={"삭제하기"} ></Button>
                                    <Button anyprop={(e) => onDismissHandler('update', true, item)} class={"subtitle"} icon="fa-light fa-pen-to-square" label={"수정하기"} ></Button>
                                    <a onClick={(e) => setToggle(toggle === item ? {} : item)}><FontAwesomeIcon icon={["fa-light", toggle === item ? "fa-chevron-up": "fa-chevron-down"].join(" ")} /></a>
                                </Element>
                            </Element>
                            <Element tag={`div`} className={styles.info}>
                                <Element tag={`div`}><Element tag={`span`}>등록일 {item.REG_DT}</Element></Element><Element tag={`hr`} />
                                <Element tag={`div`}><Element tag={`span`}>{item.TYPE === 1 ? '멤버스' : item.TYPE === 2 ? '파트너스' : null}</Element></Element>
                            </Element>
                        </Element>
                        {toggle === item ? 
                            <Element tag={`article`}>
                                <Element tag={`pre`}>{item.CONTENT}</Element>
                            </Element>
                        : null}
                    </Element>
                )}
            </Element>
        </Element>
        {isOpen.add ? <BoardAdd isOpen={isOpen.add} onDismiss={onDismissHandler} /> : null}
        {isOpen.update ? <BoardUpdate isOpen={isOpen.update} data={dataRef} onDismiss={onDismissHandler} /> : null}
        </>
	)
})
export default List;