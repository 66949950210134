import React, { useEffect, useState } from 'react';
//styles
import styles from 'src/asset/scss/styles/brandItemList.module.scss';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//hooks
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//브랜드로고이미지
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

export default function ItemIndex01(props) {
    const { isDisplay, data } = props;//props
    //hook
    const { pageRef,//무한로딩페이지DOM
        brandList,//브랜드리스트데이터
        brandListAPI//브랜드리스트API
    } = useBrandHook();
    /* ==================== 브랜드리스트 ==================== */
    useEffect(() => {
        if(isDisplay) {
            if(brandList.length <= 0) { brandListAPI(0, null); } //브랜드리스트API
            if(data.current !== null && brand === 0) { setBrand(data.current); } //validateCheck
            props.onValid(data.current, false);
        }
    }, [ isDisplay ]);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if(inView && (pageRef.current != null)) { brandListAPI(null, null); }//브랜드리스트API
    }, [inView]);
    /* ==================== 브랜드리스트 ==================== */
    /* ==================== 선택된 브랜드 ==================== */
    const [ brand, setBrand ] = useState(0);
    /* ==================== 선택된 브랜드 ==================== */

    return ( 
        <div style={{ display:(isDisplay ? 'flex' : 'none') }} className={styles.popupBrandListArea}>
            {brandList.length > 0 ? 
                <>
                <ul>
                    {brandList.map((item) => (
                        <li key={item.BRAND_CD} onClick={(e) => {setBrand(item); props.onValid(item, false);}}
                            className={(data.current !== null && brand == 0 && data.current.BRAND_CD == item.BRAND_CD) || brand.BRAND_CD === item.BRAND_CD ? styles.selected : null}>
                            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={`brand/logo/gray` + item.IMG_SRC} alt={item.BRAND_KOR_NAME} />
                            <span>{item.BRAND_KOR_NAME}</span>
                        </li>
                    ))}
                </ul>
                {brandList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div> : null}
                </>
            : null }
        </div>
    )
}