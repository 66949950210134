import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/brandItemList.module.scss';

export default function _stepper({index}) {
    const array = [{name:'제조사 선택', select:index == 0 ? styles.selected : null}, {name:'차량 정보 입력', select:index == 1 ? styles.selected : null}, {name:'제원 정보 입력', select:index == 2 ? styles.selected : null}];

    return ( 
        <div className={styles.stepperArea}>
            {array.map((item, i) =>
                <React.Fragment key={i}>
                    <span className={item.select}>{item.name}</span>
                    {i <= 1 ? <FontAwesomeIcon icon="fa-light fa-chevron-right" /> : null}
                </React.Fragment>
            )}
        </div>
    );
}