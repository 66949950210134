import React, { PureComponent, useEffect } from 'react';
//chart
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트

export default function MemberVehicleRankingPieChart({data}) {
    const COLORS = ['#A2B7FF', '#6285FF', '#6153FF', '#8850FF', '#BC50FF', '#E064FF', '#FF83EB', '#A1A1A1'];//컬러배열
    const RADIAN = Math.PI / 180;
    //value위치선정
    const renderLabelPosition = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + (radius - 10) * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">{`${(percent * 100).toFixed(0)}%`}</text>
        );
    };

    class MemberVehicleRankingPieChart extends PureComponent {
        render() {
        return (
            <Element tag={`div`}>
                <ResponsiveContainer width="100%" height="100%" isAnimationActive={false}>
                    <PieChart width={200} height={200}>
                        <Pie cx="50%" cy="50%" data={data}
                            labelLine={false} label={renderLabelPosition}
                            innerRadius={50} outerRadius={100}
                            fill="#FF0000" dataKey="value">
                            {data.map((entry, i) => (
                            <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <Element tag={`ul`} className={styles.dataListArea}>
                    {data.length > 0 && data.map((item, i) => 
                        <Element key={item.BRAND_KOR_NAME} tag={`li`}>
                            <div style={{background:(item.name === '기타' ? COLORS[7] : COLORS[i])}}></div>
                            <span>{item.BRAND_KOR_NAME}</span>
                            <label>{item.CNT}건</label>
                        </Element>   
                    )}
                </Element>
            </Element>
        )}
    } return ( <MemberVehicleRankingPieChart /> )
}
