import React, { forwardRef, useEffect, useRef } from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//modalPopup
import Modal from 'react-modal';
import 'src/asset/scss/plugin/modalPopup.scss';
//hook
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
//Comp
import Stepper from './PopupStepper';//현재단계
import Index01 from './ItemIndex01';//차량선택
import Index02 from './ItemIndex02';//차량명&이미지입력
import Index03 from './ItemIndex03';//제원정보입력

export default function ItemUpdate(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
            height: "100%"
        }
    };
    const { axiosPostHandler } = useAxiosHook();//axios
    const { isOpen, data } = props;//props
    const { childRef//하위컴포넌트
        , brandRef//브랜드정보ref
        , itemRef//차량정보ref
        , itemInfoRef//제원입력값ref
        , onCloseHandler
        , brandModelOnSubmitAPI//브랜드모델 등록 및 수정 API
    } = useBrandHook();
    /* ==================== 현재모델데이터 ==================== */
    //현재모델정보API
    const item = useRef({});
    const brandItemInfoAPI = (async() => {
        const DATA = { MODEL_CD:data.current.MODEL_CD };
        const result = await axiosPostHandler(`/brandItemInfoForJson.do`, DATA);
        console.log(result)
        if(!result) {}
        if(result.message !== "SUCCESS") {}
        if(result.message === "SUCCESS") {
            item.current = result.data;
        }
    });
    useEffect(() => {
        if(isOpen) { brandItemInfoAPI(); }//제원정보API
    }, [isOpen]);
    /* ==================== 현재모델데이터 ==================== */
    /* ==================== 브랜드모델 수정 ==================== */
    const onSubmitHandler = async() => {
        const result = await brandModelOnSubmitAPI(2, data.current.MODEL_CD);//브랜드모델 등록 및 수정 API
        if(!result) { return; }
        await childRef.current.uploadBrandItemImageAPI(result, brandRef.current.BRAND_CD, 2);//브랜드모델이미지업로드
        onCloseHandler(props.onDismiss, "update");//팝업닫기
    }
    /* ==================== 브랜드모델 수정 ==================== */
    
    class NavTabs extends React.Component {
        state = { isDisabled:true, index:0 };
        handleChange = (e, value) => {
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true, index:value });
        };
        onValidItem = (item, value) => {
            if(item != null) { brandRef.current = item; }
            if(this.state.isDisabled != value) {
                this.setState({ ...this.state, isDisabled: value });
            }
        };
        render() {
            const { index } = this.state;

            return (
                <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles} ariaHideApp={false}>
                    <div className="popupContainer">
                        <div className="popupHeader"><h1>차량 수정</h1>
                            <Stepper index={index} />
                        </div>
                        <div className="popupContainer">
                            <div>
                                <Index01 isDisplay={index === 0 ? true : false} data={data} brand={brandRef} onValid={this.onValidItem} />
                                <Index02 isDisplay={index === 1 ? true : false} data={item.current} inputRef={itemRef} onValid={this.onValidItem} choose={props.choose} ref={childRef} />
                                <Index03 isDisplay={index === 2 ? true : false} data={item.current} inputRef={itemInfoRef} onValid={this.onValidItem} />
                            </div>
                        </div>
                        <div className="popupFooter">
                            <div className="btnArea">
                                <button onClick={(e) => onCloseHandler(props.onDismiss, "update")} className="closeButton"><FontAwesomeIcon icon="fa-light fa-xmark" /><span>닫기</span></button>
                                {index > 0 ?
                                    <button onClick={(e) => this.handleChange(e, (index - 1))} className="secondaryButton"><span>이전</span></button> 
                                : null}
                                <button disabled={this.state.isDisabled} onClick={(e) => index === 2 ? onSubmitHandler() : this.handleChange(e, (index + 1))}
                                    className="submitButton"><span>확인</span></button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        }
    } return ( <NavTabs /> )
}