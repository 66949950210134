import React, { useState, useRef } from 'react';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
import useComnHook from '../comn/comn.hook';
//store
import useAdminStore from 'src/webapp/stores/admin.store';

const useBrandHook = () => {
    //hook
    const { axiosPostHandler } = useAxiosHook();//axios
    //store
    const { admin } = useAdminStore();
    //ref
    const pageRef = useRef(0);//infinityObserver페이지DOMRef
    const scrollRef = useRef();//elementScroll관리Ref
    const isLock = useRef(false);//더블클릭방지Ref
    //Ref
    const childRef = useRef();
    const brandRef = useRef([]);//아이템기본정보
    const itemRef = useRef([]);//아이템기본정보
    const itemInfoRef = useRef([]);//아이템제원정보
    const checkList = useRef([]);//수락 또는 거절 체크리스트

    /* ==================== 제조사리스트 ==================== */
    const [ brandList, setBrandList ] = useState([]);
    //제조사리스트API
    const brandListAPI = (async (refresh, isDelete) => {
        if(refresh && isDelete) { pageRef.current = 0; }
        const DATA = { BRAND_CD:pageRef.current, DEL_YN:isDelete ? "" : "N" };
        const result = await axiosPostHandler(`brandMasterListForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message != "SUCCESS") { pageRef.current = null; }
        if(result.message == "SUCCESS") { 
            const responseData = pageRef.current === 0 ? result.data : brandList.concat(result.data);
            setBrandList(responseData);
            pageRef.current = (result.data.length >= 20 ? result.data[result.data.length - 1].BRAND_CD : null);
            // if(refresh && isDelete) { brandListAPI(null, true); }
        } 
    });
    /* ==================== 제조사리스트 ==================== */
    /* ==================== 제조사별모델리스트 ==================== */
    const [ modelList, setModelList ] = useState([]);
    //제조사별모델리스트API
    const brandItemListAPI = (async (type, brandCd) => {//type - 0:최초로딩, 1:무한로딩 2. 리스트전체로딩
        if(isLock.current) { return; }

        pageRef.current = (type === 0 ? 20 : type === 1 ? pageRef.current : 0 );
        if(type === 0) { checkList.current = []; }
        isLock.current = true;

        const DATA = { "BRAND_CD":brandCd, "ROWNUM":pageRef.current };
        const result = await axiosPostHandler(`brandItemListForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result) { return; }
        if(result.message != "SUCCESS") { pageRef.current = null; }
        if(result.message == "SUCCESS") { 
            setModelList(pageRef.current == 20 || type == 2 ? result.data : modelList.concat(result.data));
            pageRef.current = (result.data.length >= 20 ? (pageRef.current + 20)  : null);
            if(type === 0) {//브랜드변경시 모델리스트 스크롤 최상단으로 올리기
                scrollRef.current?.scrollIntoView({ behavior:'instant' });//애니메이션옵션 속성값 behavior:'smooth'
            }
        } 
    });
    /* ==================== 제조사별모델리스트 ==================== */
    
    /* ==================== 차량등록 및 차량정보수정 ==================== */
    const brandModelOnSubmitAPI = async(type, MODEL_CD) => {//type - 1.등록, 2.수정
        if(isLock.current) { return; }

        const DATA = { BRAND_CD:brandRef.current.BRAND_CD, MODEL_CD:MODEL_CD, MODEL_KOR_NAME:itemRef.current[0].value, MODEL_ENG_NAME:itemRef.current[1].value,
            INFO_1:itemInfoRef.current[0].value, INFO_2:itemInfoRef.current[1].value, INFO_3:itemInfoRef.current[2].value, INFO_4:itemInfoRef.current[3].value, INFO_5:itemInfoRef.current[4].value, INFO_6:itemInfoRef.current[5].value, INFO_7:itemInfoRef.current[6].value, INFO_8:itemInfoRef.current[7].value, INFO_9:itemInfoRef.current[8].value, INFO_10:itemInfoRef.current[9].value, INFO_11:itemInfoRef.current[10].value, INFO_12:itemInfoRef.current[11].value, INFO_13:itemInfoRef.current[12].value, INFO_14:itemInfoRef.current[13].value, INFO_15:itemInfoRef.current[14].value, INFO_16:itemInfoRef.current[15].value,
            LAST_UPDATE_USER:admin.COMN_ID, SUBMIT_TYPE:type };
        const result = await axiosPostHandler(`brandItemRegAndUpdateForJson.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result) { return false; }
        if(result.message != "SUCCESS") { return false; }
        if(result.message == "SUCCESS") { 
            return result.data.result;//MODEL_CD코드값
        }
    }
    /* ==================== 모델 등록 및 수정 ==================== */
    /* ==================== 모델 등록 및 수정 팝업닫기 ==================== */
    const onCloseHandler = (handler, value) => {
        handler(value, false);
    }
    /* ==================== 모델 등록 및 수정 팝업닫기 ==================== */

    /* ==================== 브랜드 등록 및 수정 ==================== */
    //브랜드 등록 및 수정API
    const brandMasterRegAndUpdateAPI = async(item, type) => {//type - 1.등록 2.수정
        if(isLock.current) { return; }
        const DATA = { BRAND_CD:(type === 2 ? item.BRAND_CD : 0), BRAND_KOR_NAME:brandRef.current[0].value, BRAND_ENG_NAME:brandRef.current[1].value, DEL_YN:(type === 2 ? item.DEL_YN : "N"), LAST_UPDATE_USER:admin.COMN_ID, SUBMIT_TYPE:type };
        const result = await axiosPostHandler(`brandMasterRegAndUpdateForJson.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result) { return; }
        if(result.message != "SUCCESS") { return false; }
        if(result.message == "SUCCESS") { return result.data; } 
    }
    /* ==================== 브랜드 등록 및 수정 ==================== */


    return { pageRef,//페이지 DOM 정보
        brandListAPI, brandList,//제조사 데이터
        brandItemListAPI, checkList, modelList, scrollRef,//제조사별 모델 리스트
        childRef//하위 컴포넌트
        , brandRef, itemRef, itemInfoRef, brandModelOnSubmitAPI//모델 등록 및 수정
        , onCloseHandler//팝업 on & off
        , brandMasterRegAndUpdateAPI//브랜드 등록 및 수정
    };
}

export default useBrandHook;