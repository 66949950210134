import { useRef } from 'react';
//hooks
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//store
import useAdminStore from 'src/webapp/stores/admin.store';

const useBoardHook = () => {
    const { axiosPostHandler } = useAxiosHook();//axios
    //store
    const { admin } = useAdminStore();
    //hook
    const { setOnMessageHandler } = useComnHook();//submitPopupMessage
    const isLock = useRef(false);//더블클릭방지

    /* ==================== 공지사항 등록, 수정 및 삭제 통합API ==================== */
    const inputRef = useRef([]);//등록 및 수정InputRef
    const boardNoticeRegAndUpdateAPI = async(data, type) => {//type - 1.등록, 2.수정 3.삭제
        if(isLock.current) { return; }

        const msgType = type === 1 ? '등록이' : type === 2 ? '수정이' : type === 3 ? '삭제가' : null;
        let DATA = {};
        if(type == 3){//공지사항삭제 데이터
            DATA = { SEQ_NO:data.SEQ_NO, DEL_YN:'Y', LAST_UPDATE_USER:admin.COMN_ID, SUBMIT_TYPE:2 };
        } else {//공지사항 등록 및 수정 데이터
            const TYPE = inputRef.current[2].value !== 'on' ? inputRef.current[2].value : 1;
            DATA = { SEQ_NO:data?.SEQ_NO ?? 0, SUBJECT:inputRef.current[0].value, CONTENT:inputRef.current[1].value, TYPE:TYPE, DEL_YN:'N', REG_ID:admin.COMN_ID, LAST_UPDATE_USER:admin.COMN_ID, SUBMIT_TYPE:type };
        }
        const result = await axiosPostHandler(`boardNoticeRegAndUpdateForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result || result.message !== "SUCCESS") { setOnMessageHandler(2, (msgType + ' 완료되지 않았어요. 다시 시도해주세요.')); return; }
        // // if(!result) {  return false; }
        // // if(result.message !== "SUCCESS") { return false; }
        if(result.message === "SUCCESS") { 
            if(type === 2) {
                data.SUBJECT = inputRef.current[0].value;
                data.CONTENT = inputRef.current[1].value;
                data.TYPE    = inputRef.current[2].value !== 'on' ? inputRef.current[2].value : 1;
            }
            setOnMessageHandler(1, (msgType + ' 성공적으로 처리되었습니다.'));
            return true;
        }
    }
    /* ==================== 공지사항 등록, 수정 및 삭제 통합API ==================== */

    return { 
        inputRef, boardNoticeRegAndUpdateAPI
    };
}

export default useBoardHook;